import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import Toast from "../subcomponents/Toast";
import Loader from "../subcomponents/Loader";
import SmallLoader from "../subcomponents/SmallLoader";

const LinkDetailsTemp2 = ({ id, data, setToastVisible, setCountChild }) => {
  // const [isValid, setIsValid] = useState(false);
  const [input, setInput] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [utm, setUTM] = useState(false);
  const [password, setPassword] = useState(false);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [isValidUTM, setIsValidUTM] = useState(null);
  const [utmL, setUTML] = useState("");
  const [messageUTM, setMessageUTM] = useState("");
  const [source, setSource] = useState("");
  const [messageSource, setMessageSource] = useState("");
  const [isValidSource, setIsValidSource] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [messageCampaign, setMessageCampaign] = useState("");
  const [isValidCampaign, setIsValidCampaign] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cnfPasswordVisible, setCnfPasswordVisible] = useState(false);

  const [content, setContent] = useState("");
  const [passwordL, setPasswordL] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");

  const [medium, setMedium] = useState("");
  const [messageMedium, setMessageMedium] = useState("");
  const [isValidMedium, setIsValidMedium] = useState(null);
  const [DOMAINS, SETDOMAINS] = useState(null);
  const [slug, setSlug] = useState("");
  const [term, setTerm] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null);
  const [isValidTerm, setIsValidTerm] = useState(null);
  const [domainId, setDomainId] = useState("");
  const [slugValid, isSlugValid] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [slugMessage, setSlugMessage] = useState("");
  const [prevUtm, setPrevUtm] = useState("");
  const [prevMedium, setPrevMedium] = useState("");
  const [prevSource, setPrevSource] = useState("");
  const [prevTerm, setPrevTerm] = useState("");
  const [prevCampaign, setPrevCampaign] = useState("");
  const [prevContent, setPrevContent] = useState("");

  const handleToastChange = () => {
    setToastVisible(false);
  };
  useState(() => {
    if (data) {
      setUTM(data?.data.utmEnabled);
      if (data.data.utmEnabled) {
        setUTML(data?.data?.utm?.utmId);
        setPrevUtm(data?.data?.utm?.utmId);
        setSource(data?.data?.utm?.source);
        setPrevSource(data?.data?.utm?.source);
        setCampaign(data?.data?.utm?.campaign);
        setPrevCampaign(data?.data?.utm?.campaign);
        setContent(data?.data?.utm?.content);
        setPrevContent(data?.data?.utm?.content);
        setMedium(data?.data?.utm?.medium);
        setPrevMedium(data?.data?.utm?.medium);
        setTerm(data?.data?.utm?.term);
        setPrevTerm(data?.data?.utm?.term);
      }
    }
  }, [data, utm]);

  const resetForm = () => {
    setUrl("");
    setTitle("");
    setUTML("");
    setSource("");
    setMedium("");
    setCampaign("");
    setContent("");
    setTerm("");
    setSlug("");
    setPasswordL("");
    setConfirmPassword("");
    setUTM(false);
    setPassword(false);
    setIsValidSource(null);
    setIsValid(null);
    setIsValidTitle(null);
    setIsValidTerm(null);
    setIsValidCampaign(null);
    setIsValidConfirmPassword(null);
    setIsValidContent(null);
    setIsValidMedium(null);
    setIsValidPassword(null);
    setIsValidUTM(null);
  };

  const validateUTM = (utmL) => {
    return utmL.length > 0;
  };
  const handleChangeUTM = (e) => {
    const value = e.target.value;

    setUTML(value);
    if (value.includes("&")) {
      setIsValidUTM(false);
      setMessageUTM("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateUTM(value); // Validate the updated value
      setIsValidUTM(isValid);
      if (isValid) {
        // setIsValidTitle(true);
        setMessageUTM("");
      } else {
        // setIsValidTitle(false);
        setMessageUTM("Please provide utm id");
      }
    }
  };

  const validateSource = (source) => {
    return source.length > 0;
  };
  const handleChangeSource = (e) => {
    const value = e.target.value;
    setSource(value);
    if (value.includes("&")) {
      setIsValidSource(false);
      setMessageSource("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateSource(value);
      setIsValidSource(isValid);

      if (isValid) {
        // setIsValidTitle(true);
        setMessageSource("");
      } else {
        // setIsValidTitle(false);
        setMessageSource("Please provide utm source");
      }
    }
  };
  const validateMedium = (message) => {
    return message.length > 0;
  };
  const handleChangeMedium = (e) => {
    const value = e.target.value;
    setMedium(value);
    if (value.includes("&")) {
      setIsValidMedium(false);
      setMessageMedium("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateMedium(value);

      setIsValidMedium(isValid);

      if (isValid) {
        // setIsValidTitle(true);
        setMessageMedium("");
      } else {
        // setIsValidTitle(false);
        setMessageMedium("Please provide utm medium");
      }
    }
  };

  const validateCampaign = (campaign) => {
    return campaign.length > 0;
  };
  const handleChangeCampaign = (e) => {
    const value = e.target.value;
    setCampaign(value);
    if (value.includes("&")) {
      setIsValidCampaign(false);
      setMessageCampaign("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateCampaign(value);
      setIsValidCampaign(isValid);

      if (isValid) {
        setMessageCampaign("");
      } else {
        setMessageCampaign("Please provide utm campaign");
      }
    }
  };
  const [isValidContent, setIsValidContent] = useState(null);
  const handleChangeTerm = (e) => {
    const value = e.target.value;
    // const value = e.target.value;
    setTerm(value);
    if (value.includes("&")) {
      // alert("err");
      setIsValidTerm(false);
      console.log("Cannot enter '&' in utm term");
    } else {
      setIsValidTerm(null);
    }
  };
  const handleChangeContent = (e) => {
    const value = e.target.value;
    // const value = e.target.value;
    setContent(value);
    if (value.includes("&")) {
      // alert("err");
      setIsValidContent(false);
      console.log("Cannot enter '&' in utm content");
    } else {
      setIsValidContent(null);
    }
  };

  const toggleUTM = () => {
    setUTM(!utm);
  };
  const updateLinkApi = async () => {
    setLoading(true);
    const data = {
      linkId: id,

      utmEnabled: utm ? true : false,

      utmId: utm ? utmL : "",
      source: utm ? source : "",
      medium: utm ? medium : "",
      campaign: utm ? campaign : "",
      term: utm ? term : "",
      content: utm ? content : "",
    };

    console.log("data sent : " + JSON.stringify(data));

    const token = JSON.parse(localStorage.getItem("token"));

    const config = {
      method: "post",
      url: "/atz/links/update-link",
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
      data: { data },
    };

    try {
      const updateLinkResponse = await axiosInstance(config);
      console.log(updateLinkResponse.status);

      if (updateLinkResponse.status === 200) {
        setCountChild((prev) => prev + 1);
        setToastVisible(true);
        setTimeout(() => {
          setToastVisible(false);
        }, 5000);
      }
    } catch (e) {
      if (e.response.status === 404) {
        console.log(e.response);
        setToastVisible(false);
      } else {
        console.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUTM = () => {
    if (utm) {
      //utd id source medium campaign
      if (utmL.length < 1) {
        setIsValidUTM(false);
        setMessageUTM("Please provide utm id");
      }
      if (source.length < 1) {
        setIsValidSource(false);
        setMessageSource("Please provide utm source");
      }
      if (medium.length < 1) {
        setIsValidMedium(false);
        setMessageMedium("Please provide utm medium");
      }
      if (campaign.length < 1) {
        setIsValidCampaign(false);
        setMessageCampaign("Please provide utm campaign");
      }
    }
    if (
      isValidUTM !== false &&
      isValidMedium !== false &&
      isValidCampaign !== false &&
      isValidSource !== false
    ) {
      if (
        isValidUTM ||
        isValidMedium ||
        isValidSource ||
        isValidCampaign ||
        isValidContent === null ||
        isValidTerm === null
      ) {
        setPrevUtm(utmL);
        setPrevSource(source);
        setPrevCampaign(campaign);
        setPrevContent(content);
        setPrevTerm(term);
        setPrevMedium(medium);
        updateLinkApi();
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-56 sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4  rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
          <SmallLoader />
        </div>
      )}
      {!loading && (
        <div>
          <div className=" sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4  rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
            {/* <!-- Page Header --> */}

            <div className="flex flex-col py-2 pl-1">
              {/* top section */}

              {/* end top section */}

              {/* mid section */}
              <div className="flex flex-col md:flex-row justify-between border-neutral-200 rounded-md px-4 ">
                <div className="flex flex-col flex-1 ">
                  <div className="flex flex-1 items-center sm:items-baseline justify-between">
                    <div className="text-base font-[500]">Manage UTMs</div>
                    {utm &&
                      (prevSource !== source ||
                        prevCampaign !== campaign ||
                        prevUtm !== utmL ||
                        prevTerm !== term ||
                        prevMedium !== medium ||
                        prevContent !== content) && (
                        <div>
                          {loading && (
                            <>
                              <div className="size-9 p-2 bg-[#019ca2] rounded-md flex items-center justify-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  className="w-6 h-6 animate-spin text-white font-bold"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                                  />
                                </svg>
                              </div>
                            </>
                          )}
                          {!loading && (
                            <button
                              className="bg-[#019ca2] text-white py-2 p-4 rounded-md hover:bg-[#46aaad] text-xs font-semibold"
                              onClick={handleUpdateUTM}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      )}
                  </div>
                  <div>
                    {/* MidPart Hidden */}
                    <div className="py-2 mt-3 mb-4">
                      {utm && (
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            checked
                            onClick={toggleUTM}
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                          />
                          <div className="absolute w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                          <span className="ms-14 text-sm font-medium text-gray-500 dark:text-gray-300">
                            Add UTMs to track web traffic in analytics tools
                          </span>
                        </label>
                      )}
                      {!utm && (
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            onClick={toggleUTM}
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                          />
                          <div className="absolute w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                          <span className="ms-14 text-sm font-medium text-gray-500 dark:text-gray-300">
                            Add UTMs to track web traffic in analytics tools
                          </span>
                        </label>
                      )}
                    </div>
                    <div className={`${utm ? `` : `hidden`}`}>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
                        <div className=" flex flex-col flex-1 ">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email5"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                utmL.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidUTM === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidUTM === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={utmL}
                              onChange={handleChangeUTM}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                utmL.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              UTM ID
                            </label>
                            {isValidUTM === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidUTM === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidUTM === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageUTM}
                            </p>
                          )}
                          {isValidUTM === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"SOURCE"} /> */}
                        <div className="flex-col flex-1  ">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email6"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                source.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidSource === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidSource === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={source}
                              onChange={handleChangeSource}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                source.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              SOURCE
                            </label>
                            {isValidSource === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidSource === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidSource === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageSource}
                            </p>
                          )}
                          {isValidSource === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"MEDIUM"} /> */}
                        <div className=" flex-col flex-1  relative ">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email7"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                medium.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidMedium === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidMedium === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={medium}
                              onChange={handleChangeMedium}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                medium.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              MEDIUM
                            </label>
                            {isValidMedium === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidMedium === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidMedium === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageMedium}
                            </p>
                          )}
                          {isValidMedium === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5 mb-6">
                        {/* <EmailValidationForm label={"CAMPAIGN"} /> */}
                        <div className="flex flex-col  relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-emai8l"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                campaign.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidCampaign === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidCampaign === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={campaign}
                              onChange={handleChangeCampaign}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                campaign.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              CAMPAIGN
                            </label>
                            {isValidCampaign === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidCampaign === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidCampaign === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageCampaign}
                            </p>
                          )}
                          {isValidCampaign === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"CONTENT"} /> */}
                        <div className="flex flex-col  relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email9"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                content.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidContent === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={content}
                              onChange={handleChangeContent}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                content.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              CONTENT
                            </label>

                            {isValidContent === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidContent === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {"Cannot enter '&' in utm content"}
                            </p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"TERM"} /> */}
                        <div className="flex flex-col  relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                term.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none   ${
                                isValidTerm === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={term}
                              onChange={handleChangeTerm}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                term.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              TERM
                            </label>

                            {isValidTerm === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidTerm === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {"Cannot enter '&' in utm term"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end mid section */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkDetailsTemp2;
