import React, { useEffect, useRef, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";

import { useLocation, useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import { id } from "date-fns/locale";
import SmallLoader from "../subcomponents/SmallLoader";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const CreateQr = () => {
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [logoVisible, setLogoVisible] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);
  const [qrStyle, setQrStyle] = useState("square");
  const [activeStyle, setActiveStyle] = useState("square");
  const [activeCorner, setActiveCorner] = useState("square");
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [tempUrl, setTempUrl] = useState("");
  const [tempLinkId, setTempLinkId] = useState("");
  const [qrCorner, setQrCorner] = useState({
    outer: [0],
    inner: [0],
  });
  const [isValid, setIsValid] = useState(null);
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [qrColor, setQrColor] = useState("#000");
  const [qrBgColor, setQrBgColor] = useState("#fff");
  const [slug, setSlug] = useState("");
  const [slugValid, setSlugValid] = useState(true);
  const [slugMessage, setSlugMessage] = useState("");
  const [domainId, setDomainId] = useState(null);
  const [DOMAINS, SETDOMAINS] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [activeFgColor, setActiveFgColor] = useState("000");
  const [activeBgColor, setActiveBgColor] = useState("fff");

  const [qrFile, setQRFile] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getSubscription = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/active-subscriptions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        // setLoading(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);

        if (response.status === 200) {
          setActiveSubscription(response.data.data);
        }
      } catch (error) {
        if (error.response.status === 404) {
        }

        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getSubscription();
  }, []);

  useEffect(() => {
    const getDomains = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/domain/get-domains`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axiosInstance(config);
        const data = response.data.data;
        SETDOMAINS(data);
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getDomains();
  }, []);

  useEffect(() => {
    const getTitle = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          url: url, // MANDATORY
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/website-details`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);

        if (response.data.data) {
          setIsValidTitle(true);
          setTitle(response.data.data);
        }
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };

    if (url && isValid) {
      getTitle();
    }
  }, [url]);
  useEffect(() => {
    setDomainId(DOMAINS && DOMAINS[0].id);
  }, [DOMAINS]);

  const handleQR = () => {
    const canvas = canvasRef.current?.querySelector("canvas");

    if (canvas) {
      // Convert canvas to Blob (file-like object)
      canvas.toBlob((blob) => {
        if (blob) {
          // Convert Blob to File if you need it as a file object
          const file = new File([blob], "qr-code.png", { type: "image/png" });
          setQRFile(file);

          // Example: Uploading the file or doing something else with it
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.download = "qr-code.png"; // Set the file name
          link.click(); // Programmatically click the link to trigger download
          URL.revokeObjectURL(link.href); // Clean up
          console.log(file);
        }
      }, "image/png");
    }
  };

  const tempLink = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const requestData = {
      data: {
        destinationUrl: url,
        title: title,
        domainId: domainId,
        slug: slug ? slug : "",
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/temporary-link`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    try {
      logEvent(analytics, `CTA_QR_CODES_NEXT_CLICK`);
      setLoadingNext(true);
      const response = await axios(config);
      const data = response.data.data;

      if (response.status === 200) {
        logEvent(analytics, `CTA_QR_CODES_NEXT_CLICK_SUCCESS`);
        console.log(data.id);
        setTempLinkId(data.id);
        document.getElementById("next-btn").click();
        setSlugValid(false);
        setTempUrl(data.shortenUrl);
        setLoadingNext(false);
      }
    } catch (error) {
      setLoadingNext(false);
      if (error.response.status === 409) {
        setSlugValid(false);
        setSlugMessage(error.response.data.data);
      }

      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
      // setObj({ error: true });
    }
  };
  const createQR = async () => {
    var bodyFormData = new FormData();
    const canvas = canvasRef.current?.querySelector("canvas");

    if (!canvas) {
      console.log("Canvas not found");
      return;
    }

    // Use a promise to handle the asynchronous nature of canvas.toBlob
    console.log(tempUrl.slice(8, tempUrl.length).replace("/", "_"));
    const file = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File(
            [blob],
            `${tempUrl.slice(8, tempUrl.length).replace("/", "_")}.png`,
            {
              type: "image/png",
            }
          );
          resolve(file); // Resolves the promise with the file object
        } else {
          resolve(null); // Resolve with null if blob is not created
        }
      }, "image/png");
    });

    if (!file) {
      console.log("Failed to create file from canvas");
      return;
    }

    console.log(file);

    bodyFormData.append("file", file); // Attach the file to FormData

    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const requestData = {
      data: {
        style: qrStyle,
        cornerType: activeCorner,
        foregroundColor: qrColor,
        backgroundColor: qrBgColor,
        displayBrandLogo: true,
        linkId: tempLinkId,
      },
    };

    bodyFormData.append("data", JSON.stringify(requestData));

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/create-qr`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData,
    };

    try {
      logEvent(analytics, `CTA_QR_CODES_GENERATE_CLICK`);
      setLoadingNext(true);
      console.log(JSON.stringify(bodyFormData));
      const response = await axios(config);
      const data = response.data.data;

      if (response.status === 200) {
        logEvent(analytics, `CTA_QR_CODES_GENERATE_CLICK_SUCCESS`);
        window.location.href = `/qr-code/code?id=${data.id}`;
        setLoadingNext(false);
      }
    } catch (error) {
      setLoadingNext(false);

      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
      // setObj({ error: true });
    }
  };

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, activePage]);

  const handleNext = () => {
    ///validations
    if (!isValid || !isValidTitle) {
      if (!isValidTitle && isValid) {
        setIsValidTitle(false);
        setMessageTitle("Please provide valid title");
      } else if (isValidTitle && !isValid) {
        setIsValid(false);
        if (url.length < 1) {
          setMessage("Please provide destination url");
        } else {
          setMessage("Please provide valid destination url");
        }
      } else {
        setIsValid(false);
        setIsValidTitle(false);
        if (url.length < 1) {
          setMessage("Please provide destination url");
          setMessageTitle("Please provide valid title");
        } else {
          setMessage("Please provide valid destination url");
          setMessageTitle("Please provide valid title");
        }
      }
    } else {
      //valid
      try {
        tempLink();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleReset = () => {
    setQrColor("#000");
    setQrCorner("square");
    setQrBgColor("#fff");
    setQrStyle("square");
    setActiveCorner("square");
    setActiveStyle("square");
  };
  const handleDomainChange = (e) => {
    setDomainId(e.target.value);
  };

  const handleChangeSlug = (e) => {
    setSlug(e.target.value);
  };

  const validateURL = (url) => {
    // var urlR = /^(http|https):\/\/[\w.-]+(?:[:\d])(\/.)?$/;
    var urlR =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(urlR);
    return regex.test(url);
  };

  const validateTitle = (title) => {
    return title.length > 0;
  };

  const handleChange = (e) => {
    setUrl(e.target.value);
    setIsValid(validateURL(e.target.value));
    if (isValid) {
      setMessage("");
    } else {
      if (e.target.value.length < 1)
        setMessage("Please provide destination url");
      else {
        setMessage("Please provide valid destination url");
      }
    }
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
    setIsValidTitle(validateTitle(e.target.value));
  };
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <main id="content " className="lg:ps-[260px] pt-[59px]">
        <div className="w-full p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          {/* <!-- Stepper --> */}

          <div className=" sm:p-5 sm:py-2 border-[0.5px] space-y-5 rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
            {/* <!-- Page Header --> */}
            <div data-hs-stepper="">
              {/* <!-- Stepper Nav --> */}
              <div className="flex justify-between">
                <ul className="relative flex flex-1 flex-row gap-x-2 p-4">
                  <li
                    className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
                    data-hs-stepper-nav-item='{
      "index": 1
    }'
                  >
                    <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                      <span className="size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-[#019ca2] hs-stepper-active:text-white hs-stepper-success:bg-[#019ca2] hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-[#019ca2] dark:hs-stepper-success:bg-[#019ca2] dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
                        <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                          1
                        </span>
                        <svg
                          className="hidden shrink-0 size-3 hs-stepper-success:block"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className="ms-2 text-sm font-medium text-gray-800 dark:text-neutral-200">
                        Configure code
                      </span>
                    </span>
                    <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-[#019ca2] hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-[#019ca2] dark:hs-stepper-completed:bg-teal-600"></div>
                  </li>

                  <li
                    className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
                    data-hs-stepper-nav-item='{
      "index": 2
    }'
                  >
                    <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                      <span className="size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-[#019ca2] hs-stepper-active:text-white hs-stepper-success:bg-[#019ca2] hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-[#019ca2] dark:hs-stepper-success:bg-[#019ca2] dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
                        <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                          2
                        </span>
                        <svg
                          className="hidden shrink-0 size-3 hs-stepper-success:block"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className="ms-2 text-sm font-medium text-gray-800 dark:text-neutral-200">
                        Design QR
                      </span>
                    </span>
                    <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-[#019ca2] hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-[#019ca2] dark:hs-stepper-completed:bg-teal-600"></div>
                  </li>

                  {/* <!-- End Item --> */}
                </ul>
                <div className={`py-4  ${activePage == 1 ? `` : `hidden`}`}>
                  <button
                    type="button"
                    className="sm:px-8 px-2 py-1.5 border flex items-center gap-x-1 border-[#019ca2] text-[#019ca2] rounded-r-full rounded-l-full hover:bg-[#019ca2] hover:text-white font-medium"
                    data-hs-stepper-back-btn=""
                    onClick={() => setActivePage(0)}
                  >
                    <svg
                      className="shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m15 18-6-6 6-6"></path>
                    </svg>
                    Back
                  </button>
                </div>
                <div className={`py-4 ${activePage == 0 ? `` : `hidden`}`}>
                  <button
                    type="button"
                    className={`sm:px-8 px-4 py-1.5 border border-[#019ca2] text-[#019ca2] rounded-r-full rounded-l-full hover:bg-[#019ca2] hover:text-white font-medium`}
                    onClick={handleNext}
                    disabled={loadingNext}
                  >
                    <span className={`${loadingNext ? `hidden` : ``}`}>
                      Next
                    </span>
                    <span
                      className={`text-[#019ca2] hover:text-white ${
                        loadingNext ? `` : `hidden`
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="w-4 h-4 animate-spin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path
                          fillRule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className={`py-4 hidden`}>
                  <button
                    type="button"
                    id="next-btn"
                    className="px-8 py-1.5 border border-[#019ca2] text-[#019ca2] rounded-r-full rounded-l-full hover:bg-[#019ca2] hover:text-white font-medium"
                    data-hs-stepper-next-btn=""
                    onClick={() => setActivePage(1)}
                  >
                    <span className={`${loadingNext ? `hidden` : ``}`}>
                      Next
                    </span>
                    <span
                      className={`text-[#019ca2] hover:text-white ${
                        loadingNext ? `` : `hidden`
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="w-4 h-4 animate-spin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path
                          fillRule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>

              {/* <!-- End Stepper Nav --> */}

              {/* <!-- Stepper Content --> */}
              <div className="mt-2">
                {/* <!-- First Contnet --> */}
                <div
                  data-hs-stepper-content-item='{
      "index": 1
    }'
                >
                  <div className="flex flex-col py-2 sm:px-4 space-y-2">
                    {/* top section */}
                    <div className="flex flex-col sm:flex-row justify-between items-center gap-x-5">
                      <h2 className="inline-block text-2xl font-bold text-gray-800 dark:text-neutral-200 ">
                        Create an Atz QR Code
                      </h2>
                    </div>

                    <div className="flex flex-1 flex-col sm:flex-row gap-y-4">
                      <div className="flex-1 px-4 sm:px-0">
                        <div className="flex flex-1 flex-col">
                          <div className=" border rounded-lg dark:border-neutral-700">
                            <h1 className="p-3 text-lg font-medium border-b dark:border-b-neutral-700  flex items-center gap-x-2">
                              <svg
                                viewBox="0 0 24 24"
                                className="h-5 w-5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M14.1625 18.4876L13.4417 19.2084C11.053 21.5971 7.18019 21.5971 4.79151 19.2084C2.40283 16.8198 2.40283 12.9469 4.79151 10.5583L5.51236 9.8374"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M9.8374 14.1625L14.1625 9.8374"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M9.8374 5.51236L10.5583 4.79151C12.9469 2.40283 16.8198 2.40283 19.2084 4.79151C21.5971 7.18019 21.5971 11.053 19.2084 13.4417L18.4876 14.1625"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                  ></path>{" "}
                                </g>
                              </svg>
                              Link Details
                            </h1>
                            <div className="p-3">
                              <div className="relative flex-1">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="hs-floating-input-email1"
                                  className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] dark:border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                    url.length > 0
                                      ? "pt-5 pb-2.5"
                                      : "pt-4 pb-2.5"
                                  } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                    isValid === false
                                      ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                      : isValid === true
                                      ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                      : "border-gray-200 dark:border-neutral-700 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                                  }`}
                                  placeholder="you@email.com"
                                  value={url}
                                  onChange={handleChange}
                                />

                                <label
                                  htmlFor="hs-floating-input-emai2l"
                                  className={`absolute top-0 start-0 font-bold p-3.5 px-3  h-full peer-focus:text-[10px] ${
                                    url.length > 0
                                      ? "pt-3.5 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                      : "text-[9px] peer-focus:text-[9px] "
                                  } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                                >
                                  DESTINATION URL
                                </label>
                                {isValid === true && (
                                  <svg
                                    fill="#22c55e"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 335.765 335.765"
                                    xmlSpace="preserve"
                                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                  >
                                    <g>
                                      <g>
                                        <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                      </g>
                                    </g>
                                  </svg>
                                )}

                                {isValid === false && (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                  >
                                    <path
                                      d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                      stroke="#ef4444"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                              {isValid === false && (
                                <p
                                  className="text-[12px] -top-1 font-light text-red-600"
                                  id="hs-validation-email-error-helper"
                                >
                                  {message}
                                </p>
                              )}
                              {isValid === true && (
                                <p
                                  className="text-[12px] -top-1 font-light text-green-600"
                                  id="hs-validation-email-success-helper"
                                ></p>
                              )}
                            </div>
                            <div className="p-3">
                              <div className="relative flex-1">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="hs-floating-input-email3"
                                  className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                    title.length > 0
                                      ? "pt-5 pb-2.5"
                                      : "pt-4 pb-2.5"
                                  } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 focus:outline-none  ${
                                    isValidTitle === false
                                      ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                      : isValidTitle === true
                                      ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                      : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700  dark:focus:ring-blue-950 dark:focus:border-blue-400"
                                  }`}
                                  placeholder="you@email.com"
                                  value={title}
                                  onChange={handleTitle}
                                />

                                <label
                                  htmlFor="hs-floating-input-email4"
                                  className={`absolute top-0 start-0 font-bold p-3.5 px-3  h-full peer-focus:text-[10px] ${
                                    title.length > 0
                                      ? "pt-3.5 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                      : "text-[9px] peer-focus:text-[9px] "
                                  } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                                >
                                  TITLE
                                </label>
                                {isValidTitle === true && (
                                  <svg
                                    fill="#22c55e"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 335.765 335.765"
                                    xmlSpace="preserve"
                                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                  >
                                    <g>
                                      <g>
                                        <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                      </g>
                                    </g>
                                  </svg>
                                )}

                                {isValidTitle === false && (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                  >
                                    <path
                                      d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                      stroke="#ef4444"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                              {isValidTitle === false && (
                                <p
                                  className="text-[12px] -top-1 font-light text-red-600"
                                  id="hs-validation-email-error-helper"
                                >
                                  {messageTitle}
                                </p>
                              )}
                              {isValidTitle === true && (
                                <p
                                  className="text-[12px] -top-1 font-light text-green-600"
                                  id="hs-validation-email-success-helper"
                                ></p>
                              )}
                            </div>
                          </div>

                          <div className="pt-6 space-y-2">
                            <h2 className="text-lg font-medium pt-2">
                              Short link
                            </h2>
                            <p className="text-sm">
                              This link will go to the same content as your Atz
                              QR Code. If you update the link later, it will
                              change your existing code.
                            </p>
                            <div className="flex flex-row flex-1 border dark:border-neutral-700 rounded-lg">
                              <div className="flex flex-1 flex-col">
                                <div className="font-semibold p-3 border-b dark:border-b-neutral-700 flex items-center gap-x-2 text-lg">
                                  <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    className="size-5"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      strokeWidth="0"
                                    ></g>
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <g>
                                        {" "}
                                        <path
                                          fill="none"
                                          d="M0 0h24v24H0z"
                                        ></path>{" "}
                                        <path d="M11 2.05v2.012A8.001 8.001 0 0 0 12 20a8.001 8.001 0 0 0 7.938-7h2.013c-.502 5.053-4.766 9-9.951 9-5.523 0-10-4.477-10-10 0-5.185 3.947-9.449 9-9.95zm9 3.364l-8 8L10.586 12l8-8H14V2h8v8h-2V5.414z"></path>{" "}
                                      </g>{" "}
                                    </g>
                                  </svg>
                                  Ways to share
                                </div>
                                <div className="flex">
                                  <div className="flex flex-1 p-3">
                                    <div className="flex-1 relative">
                                      <select
                                        name="links"
                                        className={`peer p-3 pl-4 pb-1 pr-10 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 focus:outline-none border-gray-200 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400 appearance-none
          `}
                                        onChange={handleDomainChange}
                                        placeholder=""
                                      >
                                        {DOMAINS?.map((domain) => (
                                          <option
                                            key={domain.id}
                                            value={domain.id}
                                          >
                                            {domain.domain}
                                          </option>
                                        ))}
                                      </select>

                                      <label
                                        className={`absolute top-0 start-0 font-bold pt-1.5 px-4 h-full peer-focus:text-[8px] text-[8px]
            truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-0 
            peer-focus:text-gray-400 dark:peer-focus:text-neutral-400
            `}
                                      >
                                        DOMAIN
                                      </label>
                                    </div>
                                  </div>
                                  <div className="flex flex-1  p-3">
                                    <div className="flex-1 relative">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="12"
                                        className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                          slug.length > 0
                                            ? "pt-5 pb-2.5"
                                            : "pt-4 pb-2.5"
                                        } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 focus:outline-none ${
                                          slugValid === false
                                            ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                            : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                                        }`}
                                        placeholder="you@email.com"
                                        value={slug}
                                        onChange={handleChangeSlug}
                                      />

                                      <label
                                        className={`absolute top-0 start-0 font-bold p-3.5 px-3 h-full peer-focus:text-[10px] ${
                                          slug.length > 0
                                            ? "text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                            : "text-[9px] peer-focus:text-[9px]"
                                        } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
                                  peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                                      >
                                        SLUG | BACK HALF
                                      </label>
                                      {slugValid === false && (
                                        <svg
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="absolute right-3 top-[22px] transform -translate-y-1/2 "
                                        >
                                          <path
                                            d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                            stroke="#ef4444"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      )}

                                      {slugValid === false && (
                                        <p
                                          className="text-[12px] -top-0.5 font-light text-red-600"
                                          id="hs-validation-email-error-helper"
                                        >
                                          {slugMessage}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex md:flex-1 flex-none max-w-md text-center justify-center items-center border dark:border-neutral-700 rounded-xl mx-4 ">
                        <div className="flex flex-col ">
                          <div className="text-sm pt-4 font-medium text-neutral-400 ">
                            PREVIEW
                          </div>

                          <div className="py-2 hidden sm:block">
                            <QRCode
                              size={300}
                              value={process.env.REACT_APP_ATZ_URL}
                              logoImage={
                                logoVisible
                                  ? require("../../assets/favicon.ico")
                                  : ""
                              }
                              logoPadding={2}
                              logoWidth={30}
                              logoHeight={30}
                              fgColor={qrColor}
                              bgColor={qrBgColor}
                              qrStyle={qrStyle}
                              eyeRadius={qrCorner}
                              ecLevel="H"
                            />
                          </div>
                          <div className="py-2 sm:hidden">
                            <QRCode
                              size={200}
                              value={process.env.REACT_APP_ATZ_URL}
                              logoImage={
                                logoVisible
                                  ? require("../../assets/favicon.ico")
                                  : ""
                              }
                              logoPadding={2}
                              logoWidth={30}
                              logoHeight={30}
                              fgColor={qrColor}
                              bgColor={qrBgColor}
                              qrStyle={qrStyle}
                              eyeRadius={qrCorner}
                              ecLevel="H"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End First Contnet --> */}

                {/* <!-- First Contnet --> */}
                <div
                  data-hs-stepper-content-item='{
      "index": 2
    }'
                  style={{ display: "none" }}
                >
                  <div
                    className={`min-h-96 flex flex-1 justify-center items-center ${
                      loadingNext ? `` : `hidden`
                    }`}
                  >
                    <SmallLoader />
                  </div>
                  <div
                    className={`flex flex-col py-2 sm:px-4 space-y-2 ${
                      loadingNext ? `hidden` : ``
                    }`}
                  >
                    {/* top section */}

                    <div className="flex flex-1 flex-col md:flex-row px-4 sm:px-0 gap-y-4 sm:gap-y-0">
                      <div className="flex-1">
                        <div className="flex flex-1 flex-col gap-y-6">
                          <div className="border dark:border-neutral-700 rounded-xl ">
                            <div className="flex flex-col sm:flex-row dark:border-b-neutral-700 justify-between items-center gap-x-5 border-b">
                              <h2 className="p-4 inline-block text-xl font-semibold text-gray-800 dark:text-neutral-200 ">
                                You can customize your QR code here!
                              </h2>
                            </div>
                            <div className="p-4 space-y-2">
                              <h1 className="text-lg font-medium">
                                Choose QR style
                              </h1>
                              <div className="flex flex-1 gap-x-4">
                                <div
                                  className={`h-16 w-16 p-2 border dark:border-neutral-700 rounded-lg cursor-pointer flex items-center justify-center ${
                                    activeStyle === "square"
                                      ? `border-2 border-[#019ca2] dark:border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrStyle("squares");
                                    setActiveStyle("square");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/boxes.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-16 w-16 p-2 border dark:border-neutral-700 rounded-lg cursor-pointer flex items-center justify-center ${
                                    activeStyle === "dots"
                                      ? `border-2 border-[#019ca2] dark:border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrStyle("dots");
                                    setActiveStyle("dots");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/dots.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-16 w-16 p-2 border dark:border-neutral-700 rounded-lg cursor-pointer flex items-center justify-center ${
                                    activeStyle === "fluid"
                                      ? ` border-2 border-[#019ca2] dark:border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrStyle("fluid");
                                    setActiveStyle("fluid");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/fluid.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="p-4 pb-5 space-y-2 ">
                              <h1 className="text-lg font-medium">
                                Choose corner type
                              </h1>
                              <div className="flex flex-1 gap-x-4 flex-wrap">
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1  ${
                                    activeCorner === "circle"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [100, 100, 100, 100],
                                      inner: [100, 100, 100, 100],
                                    });
                                    setActiveCorner("circle");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/circle.jpeg")}
                                    className=""
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "outer-circle"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [100, 100, 100, 100],
                                      inner: [0, 0, 0, 0],
                                    });
                                    setActiveCorner("outer-circle");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/outer-circle.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "inner-circle"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [0, 0, 0, 0],
                                      inner: [100, 100, 100, 100],
                                    });
                                    setActiveCorner("inner-circle");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/inner-circle.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "square"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [0],
                                      inner: [0],
                                    });
                                    setActiveCorner("square");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/sqaure.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "topleft-square"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [0, 100, 100, 100],
                                      inner: [0],
                                    });
                                    setActiveCorner("topleft-square");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/topleft-square.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "topleft-circle"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [0, 100, 100, 100],
                                      inner: [100, 100, 100, 100],
                                    });
                                    setActiveCorner("topleft-circle");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/topleft-circle.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "tleftbright-circle"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [0, 100, 0, 100],
                                      inner: [100, 100, 100, 100],
                                    });
                                    setActiveCorner("tleftbright-circle");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/tleftbright-circle.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                                <div
                                  className={`h-8 w-8 sm:h-16 sm:w-16 border dark:border-neutral-700  rounded-lg flex items-center justify-center cursor-pointer sm:p-4 p-1 ${
                                    activeCorner === "instagram"
                                      ? `border-2 border-[#019ca2]`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrCorner({
                                      outer: [25, 25, 25, 25],
                                      inner: [100, 100, 100, 100],
                                    });
                                    setActiveCorner("instagram");
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/qr-codes/instagram.jpeg")}
                                    className="object-cover"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border dark:border-neutral-700 rounded-xl">
                            <div className="space-y-2">
                              <h1 className="p-4 text-xl font-semibold border-b dark:border-b-neutral-700">
                                Choose your colors
                              </h1>
                              <h2 className="text-sm font-semibold text-gray-500 pt-2 px-4">
                                FOREGROUND COLOR
                              </h2>
                              <div className="flex flex-1 gap-x-4 px-4 flex-wrap gap-y-1">
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-black ${
                                    qrColor == "#000"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#000")}
                                ></div>

                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#4b4b4b] ${
                                    qrColor == "#4b4b4b"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#4b4b4b")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#5d4037] ${
                                    qrColor == "#5d4037"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#5d4037")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#1b2631] ${
                                    qrColor == "#1b2631"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#1b2631")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#0b3d91] ${
                                    qrColor == "#0b3d91"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#0b3d91")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#2c3e50] ${
                                    qrColor == "#2c3e50"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#2c3e50")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#4a235a] ${
                                    qrColor == "#4a235a"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrColor("#4a235a")}
                                ></div>
                              </div>
                            </div>
                            <div className="p-4  space-y-2">
                              <h2 className="text-sm font-semibold text-gray-500 pt-4">
                                BACKGROUND COLOR
                              </h2>
                              <div className="flex flex-1 gap-x-4 flex-wrap gap-y-1">
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#fff] ${
                                    qrBgColor == "#fff"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrBgColor("#fff");
                                  }}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#f0f0f0] ${
                                    qrBgColor == "#f0f0f0"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setQrBgColor("#f0f0f0");
                                  }}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#f5f5f5] ${
                                    qrBgColor == "#f5f5f5"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrBgColor("#f5f5f5")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#e8f5e9] ${
                                    qrBgColor == "#e8f5e9"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrBgColor("#e8f5e9")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#e3f2fd] ${
                                    qrBgColor == "#e3f2fd"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrBgColor("#e3f2fd")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#fff3e0] ${
                                    qrBgColor == "#fff3e0"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrBgColor("#fff3e0")}
                                ></div>

                                <div
                                  className={`h-8 w-8 cursor-pointer border rounded-full bg-[#f8e9f4] ${
                                    qrBgColor == "#f8e9f4"
                                      ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                      : ``
                                  }`}
                                  onClick={() => setQrBgColor("#f8e9f4")}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="space-y-2 border dark:border-neutral-700 rounded-xl">
                            <h1 className="p-4 text-xl font-semibold border-b dark:border-b-neutral-700">
                              Advanced options
                            </h1>
                            <div className="flex flex-1 gap-x-4 p-4">
                              <div className="">
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={logoVisible}
                                    onClick={() =>
                                      setLogoVisible((prev) => !prev)
                                    }
                                    disabled={
                                      activeSubscription?.subscription?.name ==
                                      "Freemium"
                                    }
                                  />
                                  <div className="absolute md:relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                                  <span className="ms-2 text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Show/Hide Atz logo{" "}
                                    {activeSubscription?.subscription?.name ==
                                      "Freemium" && (
                                      <span>(Upgrade to switch)</span>
                                    )}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 h-auto max-w-md text-center justify-center border dark:border-neutral-700 rounded-xl sm:mx-4 ">
                        <div className="flex flex-1 flex-col justify-between ">
                          <div className="text-xl py-4 border-b dark:border-b-neutral-700 font-semibold dark:text-neutral-300 ">
                            PREVIEW
                          </div>

                          <div className="flex sm:hidden py-2 justify-center items-center">
                            <QRCode
                              size={200}
                              value={process.env.REACT_APP_ATZ_URL}
                              logoImage={
                                logoVisible
                                  ? require("../../assets/favicon.ico")
                                  : ""
                              }
                              logoPadding={2}
                              logoWidth={30}
                              logoHeight={30}
                              fgColor={qrColor}
                              bgColor={qrBgColor}
                              qrStyle={qrStyle}
                              eyeRadius={qrCorner}
                              ecLevel="H"
                            />
                          </div>
                          <div className=" py-2 justify-center items-center sm:flex hidden">
                            <QRCode
                              size={300}
                              value={process.env.REACT_APP_ATZ_URL}
                              logoImage={
                                logoVisible
                                  ? require("../../assets/favicon.ico")
                                  : ""
                              }
                              logoPadding={2}
                              logoWidth={30}
                              logoHeight={30}
                              fgColor={qrColor}
                              bgColor={qrBgColor}
                              qrStyle={qrStyle}
                              eyeRadius={qrCorner}
                              ecLevel="H"
                            />
                          </div>
                          <div className="py-2 hidden" ref={canvasRef}>
                            <QRCode
                              size={300}
                              value={tempUrl}
                              logoImage={require("../../assets/favicon.ico")}
                              logoPadding={2}
                              logoWidth={30}
                              logoHeight={30}
                              fgColor={qrColor}
                              bgColor={qrBgColor}
                              qrStyle={qrStyle}
                              eyeRadius={qrCorner}
                              ecLevel="H"
                            />
                          </div>
                          <div
                            className="border-t dark:border-t-neutral-700 text-xl py-4 hover:bg-neutral-100 cursor-pointer font-semibold"
                            onClick={handleReset}
                          >
                            RESET
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center p-4">
                      <button
                        type="button"
                        className="px-8 py-1.5 border border-[#019ca2] text-[#019ca2] rounded-r-full rounded-l-full hover:bg-[#019ca2] hover:text-white font-medium"
                        onClick={createQR}
                      >
                        Generate QR
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- End First Contnet --> */}

                {/* <!-- First Contnet --> */}
                <div
                  data-hs-stepper-content-item='{
      "index": 3
    }'
                  style={{ display: "none" }}
                >
                  <div className="p-4 h-48 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                    <h3 className="text-gray-500 dark:text-neutral-500">
                      Third content
                    </h3>
                  </div>
                </div>
                {/* <!-- End First Contnet --> */}

                {/* <!-- Final Contnet --> */}
                <div
                  data-hs-stepper-content-item='{
      "isFinal": true
    }'
                  style={{ display: "none" }}
                >
                  <div className="p-4 h-48 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                    <h3 className="text-gray-500 dark:text-neutral-500">
                      Final content
                    </h3>
                  </div>
                </div>
                {/* <!-- End Final Contnet --> */}

                {/* <!-- Button Group --> */}

                {/* <!-- End Button Group --> */}
              </div>
              {/* <!-- End Stepper Content --> */}
            </div>
            {/* <!-- End Stepper --> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateQr;
