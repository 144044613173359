import React from "react";

const Toast = ({ onClose, update }) => {
  return (
    <>
      {update && (
        <div className="space-y-3 ">
          <div
            className="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-gray-700 dark:border-neutral-700 -mr-3"
            role="alert"
          >
            <div className="flex p-4 px-3">
              <div className="flex-shrink-0">
                <svg
                  className="size-5 text-gray-600 mt-1 dark:text-neutral-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
                  <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
                </svg>
              </div>
              <div className="ms-4">
                <div className="flex justify-between">
                  <h3 className="text-gray-800 font-semibold dark:text-white">
                    Link notification
                  </h3>
                  <button onClick={onClose} className="dark:text-white">
                    x
                  </button>
                </div>
                <div className="mt-1 text-sm text-gray-600 dark:text-neutral-400">
                  Your link has been successfully updated.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {(update === false || update === null) && (
        <div className="space-y-3 ">
          <div
            className="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-neutral-800 dark:border-neutral-700 -mr-3"
            role="alert"
          >
            <div className="flex p-4 px-3">
              <div className="flex-shrink-0">
                <svg
                  className="size-5 text-gray-600 mt-1 dark:text-neutral-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
                  <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
                </svg>
              </div>
              <div className="ms-4">
                <div className="flex justify-between">
                  <h3 className="text-gray-800 font-semibold dark:text-white">
                    Link notification
                  </h3>
                  <button onClick={onClose} className="dark:text-white">
                    x
                  </button>
                </div>
                <div className="mt-1 text-sm text-gray-600 dark:text-neutral-400">
                  Your shortened link has been successfully created
                </div>
                <div className="mt-4">
                  <div className="flex space-x-3">
                    <button
                      type="button"
                      className="text-[#019ca2] decoration-2 hover:underline font-medium text-sm focus:outline-none focus:underline dark:text-[#019ca2]"
                      onClick={() => (window.location.href = "/links")}
                    >
                      View Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
