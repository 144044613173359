import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import SmallLoader from "../subcomponents/SmallLoader";
import DashboardFooter from "../Dashboard/DashboardFooter";
const DomainSuccess = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [recordsFound, setRecordsFound] = useState(true);
  const [name, setName] = useState(null);
  console.log(id);

  useEffect(() => {
    const setupConfig = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          domainId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/domain/setup-configuration`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLoading(true);
        const response = await axios(config);
        setRecords(response.data.data);

        // setListDomains(response.data.data);
        if (response.status === 200) {
          setLoading(false);
          setRecordsFound(true);
        }
        // setSearchLinksData(data.data);
        // setNoResultFound(false);
      } catch (error) {
        setRecordsFound(false);
        setLoading(false);
        if (error.response.status === 404) {
          // setNoResultFound(true);
          // setNoDomainsFound(true);
        }
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    setupConfig();
  }, []);
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div className="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
            {loading && (
              <div className="min-h-screen flex justify-center items-center">
                <SmallLoader />
              </div>
            )}
            {!loading && !recordsFound && (
              <div className="text-sm sm:p-5 p-2">
                <div className="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                  <svg
                    className="w-48 mx-auto mb-4"
                    width="178"
                    height="90"
                    viewBox="0 0 178 90"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="27"
                      y="50.5"
                      width="124"
                      height="39"
                      rx="7.5"
                      fill="currentColor"
                      className="fill-white dark:fill-neutral-800"
                    />
                    <rect
                      x="27"
                      y="50.5"
                      width="124"
                      height="39"
                      rx="7.5"
                      stroke="currentColor"
                      className="stroke-gray-50 dark:stroke-neutral-700/10"
                    />
                    <rect
                      x="34.5"
                      y="58"
                      width="24"
                      height="24"
                      rx="4"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="66.5"
                      y="61"
                      width="60"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="66.5"
                      y="73"
                      width="77"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="19.5"
                      y="28.5"
                      width="139"
                      height="39"
                      rx="7.5"
                      fill="currentColor"
                      className="fill-white dark:fill-neutral-800"
                    />
                    <rect
                      x="19.5"
                      y="28.5"
                      width="139"
                      height="39"
                      rx="7.5"
                      stroke="currentColor"
                      className="stroke-gray-100 dark:stroke-neutral-700/30"
                    />
                    <rect
                      x="27"
                      y="36"
                      width="24"
                      height="24"
                      rx="4"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <rect
                      x="59"
                      y="39"
                      width="60"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <rect
                      x="59"
                      y="51"
                      width="92"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <g filter="url(#filter19)">
                      <rect
                        x="12"
                        y="6"
                        width="154"
                        height="40"
                        rx="8"
                        fill="currentColor"
                        className="fill-white dark:fill-neutral-800"
                        shape-rendering="crispEdges"
                      />
                      <rect
                        x="12.5"
                        y="6.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        stroke="currentColor"
                        className="stroke-gray-100 dark:stroke-neutral-700/60"
                        shape-rendering="crispEdges"
                      />
                      <rect
                        x="20"
                        y="14"
                        width="24"
                        height="24"
                        rx="4"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700 "
                      />
                      <rect
                        x="52"
                        y="17"
                        width="60"
                        height="6"
                        rx="3"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700"
                      />
                      <rect
                        x="52"
                        y="29"
                        width="106"
                        height="6"
                        rx="3"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter19"
                        x="0"
                        y="0"
                        width="178"
                        height="64"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="6" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1187_14810"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1187_14810"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>

                  <div className="max-w-sm mx-auto">
                    <p className="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                      Uh oh!
                    </p>
                    <p className="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                      It looks like you don't have any domains with this ID.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!loading && recordsFound && (
              <>
                <div className="flex flex-col justify-center items-center space-y-6">
                  <div className="flex flex-col text-center items-center text-xs ">
                    <div>
                      <img
                        src={require("../../assets/images/hourglass.png")}
                        alt="img"
                        className="size-56"
                      />
                    </div>
                    <div className=" text-3xl">Your action is required</div>
                    <div className=" text-sm font-semibold mt-2">
                      IMPORTANT: Please configure your domain’s DNS records.
                    </div>
                    <div>
                      Note that your domain may not be available immediately;
                      DNS propagation can take up to 24 hours.
                      <br /> We will notify you via email once the setup is
                      complete.
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-y-4">
                  <div className="flex flex-1  dark:border-neutral-700 border-t ">
                    <div className="flex flex-col flex-1 mt-4 px-10 gap-y-2">
                      <div className="font-semibold text-xl">Mandatory</div>
                      <div className=" text-sm">
                        To configure any domain as your personal link shortener,
                        apply the following DNS settings:
                        <ul className="">
                          <li>1. Point your A Records to Atz.</li>
                          <li>
                            2. Ensure that any existing A, AAAA, or CNAME
                            records for the{" "}
                            <strong>{records && records[0]?.name}</strong> are
                            removed.
                          </li>
                        </ul>
                      </div>
                      <div>
                        <div className="flex flex-col">
                          <div className="-m-1.5 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                              <div className="border rounded-lg shadow overflow-hidden dark:border-neutral-700 dark:shadow-gray-900">
                                <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                  <thead className="bg-gray-50 dark:bg-neutral-700">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400"
                                      >
                                        Record Type
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400"
                                      >
                                        Hostname (or name)
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400"
                                      >
                                        Points to (or address)
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                                    {records &&
                                      records?.map((record) => (
                                        <tr>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                                            {record.recordType}
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                                            {record.name}
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                                            {record.ipAddress}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 hidden">
                        Not sure how to do this?{" "}
                        <a
                          className="text-[#019ca2] cursor-pointer hover:underline"
                          href="#"
                        >
                          Learn more
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      className="bg-[#019ca2] text-white px-8 py-2 rounded-l-full rounded-r-full font-medium hover:bg-[#19bec4]"
                      onClick={() => (window.location.href = "/domains")}
                    >
                      Verify my domains
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default DomainSuccess;
