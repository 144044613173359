import React, { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import MainBody from "./MainBody";
import { useLocation } from "react-router-dom";
// Import Preline
// import "preline/preline";
import DashboardFooter from "./DashboardFooter";

const DashboardUI = () => {
  localStorage.setItem("activeLink", "/dashboard");
  const location = useLocation();

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <MainBody />
      <DashboardFooter />
    </>
  );
};

export default DashboardUI;
