import React, { useState, useEffect } from "react";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardSidebar from "./Dashboard/DashboardSidebar";
import AiModal from "./subcomponents/AiModal";
import LinkDetailCard from "./subcomponents/LinkDetailCard";
import axios from "axios";
import SearchCard from "./Analytics/SearchCard";
import DashboardFooter from "./Dashboard/DashboardFooter";

const GenAiComp = () => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [linkData, setLinkData] = useState({});
  const [linkDataLoading, setLinkDataLoading] = useState(false);
  const [aiUtilization, setAiUtilization] = useState({
    smsEnabled: true,
    sms: 1,
    emailEnabled: false,
    email: null,
    countryEnabled: false,
    countries: null,
    domainsEnabled: false,
    customDomains: null,
  });

  useEffect(() => {
    const getLink = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          linkId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/get-link`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLinkDataLoading(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);
        if (response.status === 200) {
          setLinkDataLoading(false);
          setLinkData(data);
        }
        console.log(linkData);
      } catch (error) {
        setLinkDataLoading(false);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getLink();
  }, []);

  return (
    <>
      {loading && (
        <div className="absolute w-full loaderScreen z-[110] opacity-95 bg-neutral-800 flex flex-1 justify-center items-center text-white">
          <div className="absolute loader"></div>
          <div>
            <img
              src={require("../assets/images/logo/logo_white.webp")}
              alt="logo"
              className="h-6 w-14"
            />
            <div className="absolute top-1/2 left-1/2 w-[100px] h-[100px] -mt-[50px] -ml-[50px] border-4 border-t-4 border-neutral-600 border-t-[#fff] rounded-full animate-spin"></div>
          </div>
          <div className="absolute mt-56 text-neutral-500 animate-bounce">
            Generating AI powered marketing content... Hold on!
          </div>
        </div>
      )}
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main id="content " className="lg:ps-[260px] pt-[59px]">
        <div className="w-full p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="space-y-4 flex flex-col justify-between bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 mb-3">
            <div className="flex flex-col justify-center items-center gap-y-4">
              <div>
                <span className="bg-clip-text gap-x-1 inline-flex items-center bg-gradient-to-l from-purple-400 to-[#019ca2] text-transparent text-7xl font-bold mt-4">
                  <svg
                    className="flex-shrink-0"
                    height="36px"
                    width="36px"
                    version="1.1"
                    id="_x32_"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    fill="#019ca2"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g>
                        {" "}
                        <path
                          className="st0"
                          d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                        ></path>{" "}
                        <path
                          className="st0"
                          d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                        ></path>{" "}
                        <path
                          className="st0"
                          d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                        ></path>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                  Atz Ai
                  {/* <!-- 202 --> */}
                </span>
              </div>
              <div className="px-6 space-y-3 text-center mx-auto">
                <p className="text-2xl md:text-3xl font-medium  dark:text-neutral-50">
                  Your Solution for{" "}
                  <span className="text-[#019ca2] font-bold">
                    Automated Marketing Content.{" "}
                  </span>
                </p>
                <p className="text-base text-neutral-500">
                  Atz Ai transforms your links into tailored SMS and email
                  content. By analyzing your links, it crafts compelling
                  messages and recommends optimal campaign launch times for
                  various countries. Additionally, it provides custom business
                  domain suggestions to enhance your marketing strategy.
                </p>
              </div>
            </div>
            <div className="m-0 p-3 border-0 inline-flex border-b-[1px] border-b-[#019ca2] dark:text-neutral-50 hidden">
              <div className="text-sm font-bold ">Atz Ai : </div>
              <div className="italic text-sm">
                {"\u00a0"}
                Your Solution for Automated Marketing Content
              </div>
            </div>
            {linkDataLoading && (
              <div className="p-5 space-y-4 flex flex-col  border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 mb-3 bg-neutral-50 animate-pulse h-24 mx-4"></div>
            )}
            {linkData && !linkDataLoading && (
              <div className="p-2 mx-2 sm:mx-8">
                <a href={`/links-details?id=${id}`} target="_blank">
                  <SearchCard data={linkData} />
                </a>
              </div>
            )}
            <div className="flex flex-1">
              <AiModal
                id={id}
                setLoading={setLoading}
                setAiUtilization={setAiUtilization}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default GenAiComp;

//  setCountChild={setCountChild}
