import React from "react";

const TopupPlanCard = ({ plan, isSelected, onSelect, data }) => {
  return (
    <div>
      {/* <!-- Tab Content --> */}
      <div className="block">
        <fieldset className="grid gap-y-3">
          {/* <!-- Checkbox --> */}
          <label className="py-3 px-4 flex items-center font-medium bg-white text-gray-800 cursor-pointer rounded-xl ring-1 ring-gray-200 has-[:checked]:ring-2 has-[:checked]:ring-[#019ca2] dark:bg-neutral-800 dark:text-neutral-200 dark:ring-neutral-700 dark:has-[:checked]:ring-[#019ca2]">
            <input
              type="radio"
              className="bg-transparent border-gray-200 focus:ring-white focus:ring-offset-0 dark:border-neutral-700 dark:focus:ring-neutral-800 checked:bg-[#019ca2] checked:hover:bg-[#019ca2] checked:active:bg-[#019ca2] checked:focus:bg-[#019ca2] "
              value={plan}
              checked={isSelected}
              onChange={() => onSelect(data)}
            />
            <span className="grow px-4">
              <span className="block">{plan}</span>
              <span className="block text-sm leading-6 text-gray-500 dark:text-neutral-500">
                {data.description}
              </span>
              <span className="inline-flex text-sm leading-6 text-gray-500 dark:text-neutral-500 items-center gap-x-1">
                <svg
                  className="flex-shrink-0 size-3"
                  height="24"
                  width="24"
                  version="1.1"
                  id="_x32_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                  fill="#019ca2"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path
                        className="st0"
                        d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                      ></path>{" "}
                      <path
                        className="st0"
                        d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                      ></path>{" "}
                      <path
                        className="st0"
                        d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                      ></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
                {data.aiCredits} AI credits
              </span>
            </span>
            <span className="block">
              {data.mascot}
              {data.amount}
            </span>
          </label>
        </fieldset>
      </div>
    </div>
  );
};

export default TopupPlanCard;
