import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import DashboardFooter from "../Dashboard/DashboardFooter";
import axios from "axios";
import { useLocation } from "react-router-dom";
import QrCard from "./QrCard";
import SmallLoader from "../subcomponents/SmallLoader";
import { DateRangePicker } from "react-date-range";
import NoLink from "../Links/NoLink";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const QR = () => {
  const location = useLocation();
  const [linksCount, setLinksCount] = useState(null);
  const [qrData, setQrData] = useState({});
  const [loadingQrs, setLoadingQrs] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dateFilter, setDateFilter] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [domainModal, setDomainModal] = useState(false);
  const [selectedRandomOption, setSelectedRandomOption] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [DOMAINS, SETDOMAINS] = useState([]);
  const [idx, setIdx] = useState(0);
  const [itemsPeridx, setItemsPeridx] = useState(10);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [totalFilteredRecords, setTotalFilteredRecords] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  useEffect(() => {
    const getDomains = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/domain/get-domains`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;

        SETDOMAINS(data);
        setAvailableOptions(data);
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getDomains();
  }, []);
  useEffect(() => {
    const getCount = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/qr-count`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;

        if (response.status === 200) {
          setLinksCount(data);
        }
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getCount();
  }, []);
  useEffect(() => {
    const getQrs = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          index: idx,
          itemsPerIndex: itemsPeridx,
          search: searchInput ? searchInput : "",
          fromDate: "",
          toDate: "",
          domainIds: ["All"],
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-codes`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLoadingQrs(true);
        const response = await axios(config);
        const data = response.data.data;

        if (response.status === 200) {
          setTotalFilteredRecords(data.recordsTotal);
          console.log(data);
          setQrData(data);
          setLoadingQrs(false);
        }
      } catch (error) {
        setLoadingQrs(false);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getQrs();
  }, [searchInput, idx, itemsPeridx]);

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const handleDateFilter = () => {
    setDateFilter(true);
  };
  const { startDate, endDate } = selectionRange;

  const handleSelect = (ranges) => {
    console.log(startDate.toISOString());
    console.log(endDate.toISOString());
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
    setSelectionRange(ranges.selection);
  };
  const handleInputClick = () => {
    setDomainModal(!domainModal);
  };
  const handleApplyFilter = () => {
    const getQrs = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        return;
      }
      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          return;
        }
      } catch (e) {
        console.log("failed to parse token data");
      }
      const requestData = {
        data: {
          index: 0, // MANDATORY
          itemsPerIndex: 10, // MANDATORY
          search: searchInput,
          fromDate: dateFilter
            ? startDate
              ? startDate.toISOString()
              : ""
            : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT
          toDate: dateFilter ? (endDate ? endDate.toISOString() : "") : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT,
          domainIds: selectedDomains ? selectedDomains : "All",
          // domainIds: selectedDomains ? selectedDomains : "All", // OPTIONAL ID FROM /get-domains API
        },
      };

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-codes`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingQrs(true);
        const response = await axios(config);
        // console.log(response.data.data);
        const linkk = response.data.data.links;
        // setTotalFilteredRecords(response.data.data.recordsTotal);
        // console.log(linkk);
        if (response.status === 200) {
          setNotFound(false);
          setLoadingQrs(false);
          setQrData(response.data.data);
          // setRecordsFound(true);
          // setLoading(false);
        }
        // console.log(links);
        // setFilterLoading(false);
      } catch (error) {
        // setFilterLoading(false);
        setLoadingQrs(false);
        if (error.response.status === 404) {
          setTimeout(() => {
            setNotFound(true);
            // setTotalFilteredRecords(0);
            // setRecordsFound(false);
          }, 500);
          // alert("not found");
        }

        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      } finally {
      }
    };
    getQrs();
  };
  const handleOptionClick = (option) => {
    setSelectedRandomOption((prev) => [...prev, option]);
    setAvailableOptions((prev) => prev.filter((item) => item.id !== option.id));
    setSelectedDomains((prev) => [...prev, option.id]);
    setDomainModal(false);
    // Make the API call with the selected option's id (if needed)
    console.log("Selected Domain ID:", option.id);
  };

  const removePill = (option) => {
    setSelectedRandomOption((prev) =>
      prev.filter((item) => item.id !== option.id)
    );
    setSelectedDomains((prev) => prev.filter((id) => id !== option.id));
    setAvailableOptions((prev) => [...prev, option]);
  };
  const handleIdxChange = (e) => {
    setIdx(0);
    setItemsPeridx(e.target.value);
  };
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div className="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="flex justify-between">
            <div className="text-3xl font-bold">
              QR Codes
              {linksCount > 0 && <span> ({linksCount})</span>}
            </div>
            <div className="">
              <button
                type="button"
                className="px-10 py-2 bg-[#019ca2] hover:bg-[#27797c] text-neutral-50 font-semibold rounded-md"
                onClick={() => {
                  logEvent(analytics, `CTA_QR_CODES_CREATE_NEW_CLICK`);
                  window.location.href = "/create-qr";
                }}
              >
                + Create new QR
              </button>
            </div>
          </div>
          <div className="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-5">
            <div className="flex justify-between items-center gap-x-2">
              <h2 className="font-semibold text-gray-800 dark:text-neutral-200">
                {linksCount > 0 && (
                  <span>
                    Showing {totalFilteredRecords} filtered records list
                  </span>
                )}
              </h2>
              <div className="flex items-center gap-x-2">
                {/* <!-- Import / Export Dropdown --> */}
                <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                  {/* <!-- Import / Export Button --> */}

                  {/* <!-- End Import / Export Button --> */}

                  {/* <!-- Dropdown --> */}
                  <div
                    className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
                    aria-labelledby="hs-pro-dptied"
                  >
                    <div className="p-1">
                      <button
                        type="button"
                        className="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                        data-hs-overlay="#hs-pro-dicm"
                      >
                        <svg
                          className="flex-shrink-0 mt-0.5 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="7 10 12 15 17 10" />
                          <line x1="12" x2="12" y1="15" y2="3" />
                        </svg>
                        Import contacts
                      </button>
                      <button
                        type="button"
                        className="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 "
                        data-hs-overlay="#hs-pro-decm"
                      >
                        <svg
                          className="flex-shrink-0 mt-0.5 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="17 8 12 3 7 8" />
                          <line x1="12" x2="12" y1="3" y2="15" />
                        </svg>
                        Export contacts
                      </button>
                    </div>
                  </div>
                  {/* <!-- End Dropdown --> */}
                </div>
                {/* <!-- End Import / Export Dropdown --> */}

                {/* <!-- Download Dropdown --> */}
                <button
                  type="button"
                  className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hidden"
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  aria-controls="hs-offcanvas-right"
                  data-hs-overlay="#hs-offcanvas-right"
                >
                  <svg
                    className="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="21" x2="14" y1="4" y2="4" />
                    <line x1="10" x2="3" y1="4" y2="4" />
                    <line x1="21" x2="12" y1="12" y2="12" />
                    <line x1="8" x2="3" y1="12" y2="12" />
                    <line x1="21" x2="16" y1="20" y2="20" />
                    <line x1="12" x2="3" y1="20" y2="20" />
                    <line x1="14" x2="14" y1="2" y2="6" />
                    <line x1="8" x2="8" y1="10" y2="14" />
                    <line x1="16" x2="16" y1="18" y2="22" />
                  </svg>
                  Custom Filter
                </button>
                <button
                  type="button"
                  className={`py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 ${
                    linksCount > 0 ? `` : `hidden`
                  }`}
                  data-hs-overlay="#hs-overlay-right"
                >
                  <svg
                    className="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="21" x2="14" y1="4" y2="4" />
                    <line x1="10" x2="3" y1="4" y2="4" />
                    <line x1="21" x2="12" y1="12" y2="12" />
                    <line x1="8" x2="3" y1="12" y2="12" />
                    <line x1="21" x2="16" y1="20" y2="20" />
                    <line x1="12" x2="3" y1="20" y2="20" />
                    <line x1="14" x2="14" y1="2" y2="6" />
                    <line x1="8" x2="8" y1="10" y2="14" />
                    <line x1="16" x2="16" y1="18" y2="22" />
                  </svg>
                  Filter
                  <span className="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full dark:bg-neutral-500">
                    1
                  </span>
                </button>

                <div
                  id="hs-overlay-right"
                  className="hs-overlay hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform overflow-scroll h-full w-full sm:w-auto z-[80] bg-white border-s dark:bg-neutral-800 dark:border-neutral-700"
                  tabIndex="-1"
                >
                  <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3 className="font-bold text-gray-800 dark:text-white">
                      Filters
                    </h3>
                    <button
                      type="button"
                      className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                      data-hs-overlay="#hs-overlay-right"
                    >
                      <span className="sr-only">Close modal</span>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-4 dark:text-white ">
                    <div className="space-y-2">
                      <div className="border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600 hidden">
                        <div className="p-4 relative">
                          <div className="p-2">Domains</div>
                          <input
                            className="min-w-full overflow-auto rounded-md border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 focus:border-gray-200 dark:focus:border-neutral-700 focus:ring-0 dark:text-white dark:placeholder:text-neutral-300 cursor-pointer"
                            placeholder="Select domain"
                            onClick={handleInputClick}
                          />
                          {domainModal && (
                            <div className="mt-1 flex flex-1 flex-col rounded-md border border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 z-[100]">
                              {availableOptions.map((option) => (
                                <div
                                  key={option.id}
                                  className="min-w-full border-b p-2 hover:bg-[#019ca2] dark:border-neutral-700 hover:text-white cursor-pointer"
                                  onClick={() => handleOptionClick(option)}
                                >
                                  {option.domain}
                                </div>
                              ))}
                            </div>
                          )}

                          {/* Pills */}
                          <div className="relative py-2 dark:text-neutral-400 z-[80]">
                            <div className="flex flex-wrap gap-x-2 gap-y-2">
                              {selectedRandomOption.map((option) => (
                                <div
                                  key={option.id}
                                  className="dark:bg-neutral-900 border border-gray-300 dark:border-neutral-700 rounded-full px-4 p-2 cursor-pointer gap-x-2 flex"
                                  onClick={() => removePill(option)}
                                >
                                  <div>{option.domain}</div>
                                  <div>x</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* Pills END */}
                        </div>
                      </div>

                      <div className="p-4 pb-0 flex flex-col  border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                        <span className="flex">Date</span>
                        <div
                          className="flex py-2 overflow-auto"
                          onClick={handleDateFilter}
                        >
                          <DateRangePicker
                            initialFocusedRange={null}
                            color="#019ca2"
                            rangeColors="019ca2"
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="p-4 font-semibold text-xs flex flex-1 gap-x-6">
                        <div className="flex flex-1">
                          <button
                            data-hs-overlay="#hs-overlay-right"
                            className="p-2 border w-full dark:border-neutral-600 border-gray-200 rounded-md hover:bg-neutral-300 dark:hover:bg-neutral-700"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="flex flex-1">
                          <button
                            data-hs-overlay="#hs-overlay-right"
                            className="p-2 bg-[#019ca2] w-full text-white rounded-md hover:bg-[#38afb4] "
                            onClick={handleApplyFilter}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- End Download Dropdown --> */}
              </div>

              {/* <!-- Button Group --> */}

              {/* <!-- End Button Group --> */}
            </div>
            <div className="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5 hidden">
              <div>
                {/* <!-- Search Input --> */}
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                    <svg
                      className="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <path d="m21 21-4.3-4.3" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    value={searchInput}
                    onChange={handleSearchInput}
                    className="input-style py-[7px] px-3 ps-10 block w-full bg-inherit border border-neutral-200
                              dark:border-neutral-700 focus:border-neutral-200
                              dark:focus:border-neutral-700
                              focus:ring-0 rounded-lg text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:border-neutral-400"
                    placeholder="Search links with titles"
                  />
                </div>
                {/* <!-- End Search Input --> */}
              </div>
              {/* <!-- End Col --> */}

              <div className="flex md:justify-end items-center gap-x-2">
                {/* <!-- Import / Export Dropdown --> */}
                <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                  {/* <!-- Import / Export Button --> */}

                  {/* <!-- End Import / Export Button --> */}

                  {/* <!-- Dropdown --> */}
                  <div
                    className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
                    aria-labelledby="hs-pro-dptied"
                  >
                    <div className="p-1">
                      <button
                        type="button"
                        className="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                        data-hs-overlay="#hs-pro-dicm"
                      >
                        <svg
                          className="flex-shrink-0 mt-0.5 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="7 10 12 15 17 10" />
                          <line x1="12" x2="12" y1="15" y2="3" />
                        </svg>
                        Import contacts
                      </button>
                      <button
                        type="button"
                        className="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 "
                        data-hs-overlay="#hs-pro-decm"
                      >
                        <svg
                          className="flex-shrink-0 mt-0.5 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="17 8 12 3 7 8" />
                          <line x1="12" x2="12" y1="3" y2="15" />
                        </svg>
                        Export contacts
                      </button>
                    </div>
                  </div>
                  {/* <!-- End Dropdown --> */}
                </div>
                {/* <!-- End Import / Export Dropdown --> */}

                {/* <!-- Download Dropdown --> */}
                <button
                  type="button"
                  className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hidden"
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  aria-controls="hs-offcanvas-right"
                  data-hs-overlay="#hs-offcanvas-right"
                >
                  <svg
                    className="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="21" x2="14" y1="4" y2="4" />
                    <line x1="10" x2="3" y1="4" y2="4" />
                    <line x1="21" x2="12" y1="12" y2="12" />
                    <line x1="8" x2="3" y1="12" y2="12" />
                    <line x1="21" x2="16" y1="20" y2="20" />
                    <line x1="12" x2="3" y1="20" y2="20" />
                    <line x1="14" x2="14" y1="2" y2="6" />
                    <line x1="8" x2="8" y1="10" y2="14" />
                    <line x1="16" x2="16" y1="18" y2="22" />
                  </svg>
                  Custom Filter
                </button>
                <button
                  type="button"
                  className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  data-hs-overlay="#hs-overlay-right"
                >
                  <svg
                    className="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="21" x2="14" y1="4" y2="4" />
                    <line x1="10" x2="3" y1="4" y2="4" />
                    <line x1="21" x2="12" y1="12" y2="12" />
                    <line x1="8" x2="3" y1="12" y2="12" />
                    <line x1="21" x2="16" y1="20" y2="20" />
                    <line x1="12" x2="3" y1="20" y2="20" />
                    <line x1="14" x2="14" y1="2" y2="6" />
                    <line x1="8" x2="8" y1="10" y2="14" />
                    <line x1="16" x2="16" y1="18" y2="22" />
                  </svg>
                  Filter
                  <span className="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full dark:bg-neutral-500">
                    2
                  </span>
                </button>

                <div
                  id="hs-overlay-right"
                  className="hs-overlay hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform overflow-scroll h-full w-full sm:w-auto z-[80] bg-white border-s dark:bg-neutral-800 dark:border-neutral-700"
                  tabIndex="-1"
                >
                  <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3 className="font-bold text-gray-800 dark:text-white">
                      Filters
                    </h3>
                    <button
                      type="button"
                      className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                      data-hs-overlay="#hs-overlay-right"
                    >
                      <span className="sr-only">Close modal</span>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-4 dark:text-white">
                    <div className="space-y-2">
                      <div className="border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                        <div className="p-4 relative">
                          <div className="p-2">Domains</div>
                          <input
                            className="min-w-full overflow-auto rounded-md border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 focus:border-gray-200 dark:focus:border-neutral-700 focus:ring-0 dark:text-white dark:placeholder:text-neutral-300 cursor-pointer"
                            placeholder="Select domain"
                            onClick={handleInputClick}
                          />
                          {domainModal && (
                            <div className="mt-1 flex flex-1 flex-col rounded-md border border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 z-[100]">
                              {availableOptions.map((option) => (
                                <div
                                  key={option.id}
                                  className="min-w-full border-b p-2 hover:bg-[#019ca2] dark:border-neutral-700 hover:text-white cursor-pointer"
                                  onClick={() => handleOptionClick(option)}
                                >
                                  {option.domain}
                                </div>
                              ))}
                            </div>
                          )}

                          {/* Pills */}
                          <div className="relative py-2 dark:text-neutral-400 z-[80]">
                            <div className="flex flex-wrap gap-x-2 gap-y-2">
                              {selectedRandomOption.map((option) => (
                                <div
                                  key={option.id}
                                  className="dark:bg-neutral-900 border border-gray-300 dark:border-neutral-700 rounded-full px-4 p-2 cursor-pointer gap-x-2 flex"
                                  onClick={() => removePill(option)}
                                >
                                  <div>{option.domain}</div>
                                  <div>x</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* Pills END */}
                        </div>
                      </div>

                      <div className="p-4 pb-0 flex flex-col  border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                        <span className="flex">Date</span>
                        <div
                          className="flex py-2 overflow-auto"
                          onClick={handleDateFilter}
                        >
                          <DateRangePicker
                            initialFocusedRange={null}
                            color="#019ca2"
                            rangeColors="019ca2"
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="p-4 font-semibold text-xs flex flex-1 gap-x-6">
                        <div className="flex flex-1">
                          <button
                            data-hs-overlay="#hs-overlay-right"
                            className="p-2 border w-full dark:border-neutral-600 border-gray-200 rounded-md hover:bg-neutral-300 dark:hover:bg-neutral-700"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="flex flex-1">
                          <button
                            data-hs-overlay="#hs-overlay-right"
                            className="p-2 bg-[#019ca2] w-full text-white rounded-md hover:bg-[#38afb4] "
                            onClick={handleApplyFilter}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- End Download Dropdown --> */}
              </div>
              {/* <!-- End Col --> */}
            </div>
            {loadingQrs && (
              <div className="min-h-96 flex flex-1 justify-center items-center">
                <SmallLoader />
              </div>
            )}
            {!loadingQrs &&
              !notFound &&
              linksCount !== 0 &&
              qrData &&
              qrData?.qrCodes?.map((qrData, key) => (
                <QrCard qrData={qrData} index={key} />
              ))}
            {!loadingQrs && !notFound && linksCount !== 0 && qrData && (
              <div className="grid grid-flow-row sm:grid-cols-2 items-center gap-y-2 sm:gap-y-0 sm:gap-x-5">
                <p className="text-sm text-gray-800 dark:text-neutral-200">
                  <span className="font-medium">
                    {qrData?.qrCodes?.length + " "}
                  </span>
                  <span className="text-gray-500 dark:text-neutral-500">
                    results
                  </span>
                </p>

                {/* <!-- Pagination --> */}
                <nav className="flex justify-end items-center gap-x-1">
                  <select
                    className="py-3 px-4 pe-9 block w-20 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                    value={itemsPeridx}
                    onChange={handleIdxChange}
                  >
                    <option value="" disabled>
                      Items per page
                    </option>
                    <option value="10" selected>
                      10
                    </option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <button
                    type="button"
                    onClick={() => setIdx(idx - 1)}
                    disabled={idx === 0}
                    className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700"
                  >
                    <svg
                      className="flex-shrink-0 size-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m15 18-6-6 6-6" />
                    </svg>
                    <span aria-hidden="true" className="sr-only">
                      Previous
                    </span>
                  </button>

                  <div className="flex items-center gap-x-1">
                    <span className="min-h-[38px] min-w-[38px] flex justify-center items-center bg-gray-100 text-gray-800 py-2 px-3 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:text-white">
                      {idx + 1}
                    </span>
                    <span className="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                      of
                    </span>
                    <span className="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                      {Math.ceil(totalFilteredRecords / itemsPeridx)}
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      setIdx(idx + 1);
                      document.body.scrollTop =
                        document.documentElement.scrollTop = 0;
                    }}
                    disabled={
                      idx + 1 === Math.ceil(totalFilteredRecords / itemsPeridx)
                    }
                    className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700"
                  >
                    <span aria-hidden="true" className="sr-only">
                      Next
                    </span>
                    <svg
                      className="flex-shrink-0 size-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m9 18 6-6-6-6" />
                    </svg>
                  </button>
                </nav>
                {/* <!-- End Pagination --> */}
              </div>
            )}
            {notFound && <NoLink noMatch={true} />}
            {!loadingQrs && linksCount === 0 && (
              <div className="text-sm sm:p-5 p-2">
                <div className="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                  <svg
                    className="w-48 mx-auto mb-4"
                    width="178"
                    height="90"
                    viewBox="0 0 178 90"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="27"
                      y="50.5"
                      width="124"
                      height="39"
                      rx="7.5"
                      fill="currentColor"
                      className="fill-white dark:fill-neutral-800"
                    />
                    <rect
                      x="27"
                      y="50.5"
                      width="124"
                      height="39"
                      rx="7.5"
                      stroke="currentColor"
                      className="stroke-gray-50 dark:stroke-neutral-700/10"
                    />
                    <rect
                      x="34.5"
                      y="58"
                      width="24"
                      height="24"
                      rx="4"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="66.5"
                      y="61"
                      width="60"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="66.5"
                      y="73"
                      width="77"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="19.5"
                      y="28.5"
                      width="139"
                      height="39"
                      rx="7.5"
                      fill="currentColor"
                      className="fill-white dark:fill-neutral-800"
                    />
                    <rect
                      x="19.5"
                      y="28.5"
                      width="139"
                      height="39"
                      rx="7.5"
                      stroke="currentColor"
                      className="stroke-gray-100 dark:stroke-neutral-700/30"
                    />
                    <rect
                      x="27"
                      y="36"
                      width="24"
                      height="24"
                      rx="4"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <rect
                      x="59"
                      y="39"
                      width="60"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <rect
                      x="59"
                      y="51"
                      width="92"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <g filter="url(#filter19)">
                      <rect
                        x="12"
                        y="6"
                        width="154"
                        height="40"
                        rx="8"
                        fill="currentColor"
                        className="fill-white dark:fill-neutral-800"
                        shape-rendering="crispEdges"
                      />
                      <rect
                        x="12.5"
                        y="6.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        stroke="currentColor"
                        className="stroke-gray-100 dark:stroke-neutral-700/60"
                        shape-rendering="crispEdges"
                      />
                      <rect
                        x="20"
                        y="14"
                        width="24"
                        height="24"
                        rx="4"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700 "
                      />
                      <rect
                        x="52"
                        y="17"
                        width="60"
                        height="6"
                        rx="3"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700"
                      />
                      <rect
                        x="52"
                        y="29"
                        width="106"
                        height="6"
                        rx="3"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter19"
                        x="0"
                        y="0"
                        width="178"
                        height="64"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="6" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1187_14810"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1187_14810"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>

                  <div className="max-w-sm mx-auto">
                    <p className="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                      Uh oh!
                    </p>
                    <p className="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                      It looks like you don't have any domains created yet.
                      Start by creating one.
                    </p>
                  </div>
                  <button
                    type="button"
                    className="border border-[#019ca2] hover:bg-[#019ca2] px-16 py-2 rounded-r-full rounded-l-full text-[#019ca2] hover:text-neutral-50 font-semibold"
                    onClick={() => {
                      logEvent(analytics, `CTA_QR_CODES_CREATE_NEW_CLICK`);
                      window.location.href = "/create-qr";
                    }}
                  >
                    + New QR
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="w-full p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="space-y-4 flex flex-col justify-between bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 mb-3">
            <section className="bg-white dark:bg-gray-900 ">
              <div className="container md:flex-row flex flex-col justify-center items-center min-h-screen px-6 py-12 mx-auto">
                <div>
                  <img
                    src={require("../../assets/images/upcoming.png")}
                    className="flex flex-1 justify-center items-center sm:-ml-1 sm:-mb-2 h-28 w-52 sm:h-full sm:w-[550px]"
                  />

                  <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                    This page is currently under development.
                  </h1>
                  <p className="mt-4 text-gray-500 dark:text-gray-400">
                    We hope that the page you are looking for is being developed
                    and is supposed to be up and running by a week or two.
                  </p>

                  <div className="flex items-center mt-6 gap-x-3">
                    <button
                      className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
                      onClick={() => window.history.back()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 rtl:rotate-180"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                        />
                      </svg>

                      <span>Go back</span>
                    </button>

                    <button
                      className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-[#019ca2] rounded-lg shrink-0 sm:w-auto hover:bg-[#14bec4] dark:hover:bg-neutral-800 dark:bg-neutral-900"
                      onClick={() => (window.location.href = "/")}
                    >
                      Take me home
                    </button>
                  </div>
                </div>
                <div className="w-fit">
                  <img
                    src={require("../../assets/images/svg/404-illustration.png")}
                    className="md:h-[450px]"
                  />
                </div>
              </div>
            </section>
          </div>
        </div> */}
      </main>
    </>
  );
};

export default QR;
