import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import Toast from "../subcomponents/Toast";
import Loader from "../subcomponents/Loader";
import { valid } from "chroma-js";
import SmallLoader from "../subcomponents/SmallLoader";

const LinkDetailsTemp = ({ id, data, setToastVisible, setCountChild }) => {
  // const [isValid, setIsValid] = useState(false);

  const [input, setInput] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [utm, setUTM] = useState(false);
  const [password, setPassword] = useState(false);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [isValidUTM, setIsValidUTM] = useState(null);
  const [utmL, setUTML] = useState("");
  const [messageUTM, setMessageUTM] = useState("");
  const [source, setSource] = useState("");
  const [messageSource, setMessageSource] = useState("");
  const [isValidSource, setIsValidSource] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [messageCampaign, setMessageCampaign] = useState("");
  const [isValidCampaign, setIsValidCampaign] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cnfPasswordVisible, setCnfPasswordVisible] = useState(false);

  const [content, setContent] = useState("");
  const [passwordL, setPasswordL] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");

  const [medium, setMedium] = useState("");
  const [messageMedium, setMessageMedium] = useState("");
  const [isValidMedium, setIsValidMedium] = useState(null);
  const [DOMAINS, SETDOMAINS] = useState(null);
  const [slug, setSlug] = useState("");
  const [term, setTerm] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null);
  const [isValidTerm, setIsValidTerm] = useState(null);
  const [domainId, setDomainId] = useState("");
  const [slugValid, isSlugValid] = useState(true);

  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [prevTitle, setPrevTitle] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [slugMessage, setSlugMessage] = useState("");
  useState(() => {
    if (data) {
      setUrl(data?.data.destinationUrl);
      setPrevUrl(data?.data.destinationUrl);
      setTitle(data?.data.title);
      setPrevTitle(data?.data.title);
    }
  }, [data]);
  const handleToastChange = () => {
    setToastVisible(false);
  };

  useEffect(() => {
    const getTitle = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          url: url, // MANDATORY
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/website-details`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);

        if (response.data.data) {
          setIsValidTitle(true);
          setTitle(response.data.data);
        }
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };

    if (url && isValid) {
      getTitle();
    }
  }, [url]);

  const validateURL = (url) => {
    // var urlR = /^(http|https):\/\/[\w.-]+(?:[:\d])(\/.)?$/;
    var urlR =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(urlR);
    return regex.test(url);
  };

  const validateTitle = (title) => {
    return title.length > 0;
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setTitle(value);

    const isValid = validateTitle(value); // Validate the updated value
    setIsValidTitle(isValid);

    if (isValid) {
      setMessageTitle("");
    } else {
      // setIsValidTitle(false);
      setMessageTitle("Please provide title");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setUrl(value);
    setIsValid(validateURL(value));

    if (isValid) {
      setMessage("");
    } else {
      if (value.length < 1) setMessage("Please provide destination url");
      else {
        setMessage("Please provide valid destination url");
      }
    }
  };
  const updateLinkApi = async () => {
    console.log(loading);

    const data = {
      linkId: id,
      destinationUrl: url,
      title: title,
    };

    console.log("data sent : " + JSON.stringify(data));

    const token = JSON.parse(localStorage.getItem("token"));

    const config = {
      method: "post",
      url: "/atz/links/update-link",
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
      data: { data },
    };

    try {
      const updateLinkResponse = await axiosInstance(config);
      setLoading(false);
      console.log(updateLinkResponse.status);
      if (updateLinkResponse.status === 200) {
        setCountChild((prev) => prev + 1);
        setToastVisible((prev) => true);
        setTimeout(() => {
          setToastVisible(false);
        }, 5000);
      }
    } catch (e) {
      console.log(loading);

      console.log(loading);
      if (e.response.status === 404) {
        console.log(e.response);
        setToastVisible(false);
      } else {
        console.error(e);
      }
    }
  };
  const handleUpdateTitle = () => {
    setLoading(true);
    if (url.length === 0) {
      if (title.length < 1) {
        setIsValidTitle(false);
        setMessageTitle("Please provide title");
      }
      setIsValid(false);
      setMessage("Please provide destination url");
    } else {
      if (isValid) {
        setMessage("");
      } else {
        setMessage("Please provide valid destination url");
      }
    }
    if (
      (isValid === true && isValidTitle === null) ||
      (isValid === null && isValidTitle === true) ||
      (isValid === true && isValidTitle === true)
    ) {
      setPrevTitle(title);
      setPrevUrl(url);
      updateLinkApi();
    }
  };

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-56 sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4 rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
          <SmallLoader />
        </div>
      )}
      {!loading && (
        <div>
          <div className=" sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4 rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
            {/* <!-- Page Header --> */}

            <div className="flex flex-col py-2 pl-1">
              {/* top section */}

              {/* end top section */}

              {/* mid section */}
              <div className="flex flex-col md:flex-row justify-between border-neutral-200 rounded-md px-4 ">
                <div className="flex flex-col flex-1">
                  <div className="flex flex-1 items-center sm:items-baseline justify-between">
                    <div className="text-base font-[500]">
                      Title & Destination Url
                    </div>

                    {(prevUrl !== url || prevTitle !== title) && (
                      <>
                        {loading && (
                          <>
                            <div className="size-9 p-2 bg-[#019ca2] rounded-md flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="w-6 h-6 animate-spin text-white font-bold"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                <path
                                  fillRule="evenodd"
                                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                                />
                              </svg>
                            </div>
                          </>
                        )}
                        {!loading && (
                          <div>
                            <button
                              className="bg-[#019ca2] text-white py-2 p-4 rounded-md hover:bg-[#46aaad] text-xs font-semibold"
                              onClick={() => {
                                handleUpdateTitle();
                              }}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mt-5 mr-0 md:mr-1">
                    <div className="flex flex-col relative">
                      <div className="relative flex-1">
                        <input
                          autoComplete="off"
                          type="text"
                          id="hs-floating-input-email1"
                          className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] dark:border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                            url.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                          } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                            isValid === false
                              ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                              : isValid === true
                              ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                              : "border-gray-200 dark:border-neutral-700 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                          }`}
                          placeholder="you@email.com"
                          value={url}
                          onChange={handleChange}
                        />

                        <label
                          htmlFor="hs-floating-input-emai2l"
                          className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                            url.length > 0
                              ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                              : "text-[9px] peer-focus:text-[9px] "
                          } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                        >
                          DESTINATION URL
                        </label>
                        {isValid === true && (
                          <svg
                            fill="#22c55e"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="20px"
                            height="20px"
                            viewBox="0 0 335.765 335.765"
                            xmlSpace="preserve"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2"
                          >
                            <g>
                              <g>
                                <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                              </g>
                            </g>
                          </svg>
                        )}

                        {isValid === false && (
                          <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2"
                          >
                            <path
                              d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                              stroke="#ef4444"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                      {isValid === false && (
                        <p
                          className="text-[12px] font-light text-red-600"
                          id="hs-validation-email-error-helper"
                        >
                          {message}
                        </p>
                      )}
                      {isValid === true && (
                        <p
                          className="text-[12px] -top-1 font-light text-green-600"
                          id="hs-validation-email-success-helper"
                        ></p>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 mr-0 md:mr-1 mb-6">
                    {/* <EmailValidationForm label={"TITLE"} /> */}
                    <div className="flex flex-col  relative">
                      <div className="relative flex-1">
                        <input
                          autoComplete="off"
                          type="text"
                          id="hs-floating-input-email3"
                          className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                            title.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                          } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 focus:outline-none  ${
                            isValidTitle === false
                              ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                              : isValidTitle === true
                              ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                              : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700  dark:focus:ring-blue-950 dark:focus:border-blue-400"
                          }`}
                          placeholder="you@email.com"
                          value={title}
                          onChange={handleChangeTitle}
                        />

                        <label
                          htmlFor="hs-floating-input-email4"
                          className={`absolute top-2 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                            title.length > 0
                              ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                              : "text-[9px] peer-focus:text-[9px] "
                          } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                        >
                          TITLE
                        </label>
                        {isValidTitle === true && (
                          <svg
                            fill="#22c55e"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="20px"
                            height="20px"
                            viewBox="0 0 335.765 335.765"
                            xmlSpace="preserve"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2"
                          >
                            <g>
                              <g>
                                <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                              </g>
                            </g>
                          </svg>
                        )}

                        {isValidTitle === false && (
                          <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2"
                          >
                            <path
                              d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                              stroke="#ef4444"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                      {isValidTitle === false && (
                        <p
                          className="text-[12px] font-light text-red-600"
                          id="hs-validation-email-error-helper"
                        >
                          {messageTitle}
                        </p>
                      )}
                      {isValidTitle === true && (
                        <p
                          className="text-[12px] -top-1 font-light text-green-600"
                          id="hs-validation-email-success-helper"
                        ></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* end mid section */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkDetailsTemp;
