import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import Toast from "../subcomponents/Toast";
import Loader from "../subcomponents/Loader";
import SmallLoader from "../subcomponents/SmallLoader";

const LinkDetailsTemp4 = ({ id, data, setToastVisible, setCountChild }) => {
  // const [isValid, setIsValid] = useState(false);
  const [input, setInput] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [utm, setUTM] = useState(false);
  const [password, setPassword] = useState(false);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [isValidUTM, setIsValidUTM] = useState(null);
  const [utmL, setUTML] = useState("");
  const [messageUTM, setMessageUTM] = useState("");
  const [source, setSource] = useState("");
  const [messageSource, setMessageSource] = useState("");
  const [isValidSource, setIsValidSource] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [messageCampaign, setMessageCampaign] = useState("");
  const [isValidCampaign, setIsValidCampaign] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cnfPasswordVisible, setCnfPasswordVisible] = useState(false);

  const [content, setContent] = useState("");
  const [passwordL, setPasswordL] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");

  const [medium, setMedium] = useState("");
  const [messageMedium, setMessageMedium] = useState("");
  const [isValidMedium, setIsValidMedium] = useState(null);
  const [DOMAINS, SETDOMAINS] = useState(null);
  const [slug, setSlug] = useState("");
  const [term, setTerm] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null);
  const [isValidTerm, setIsValidTerm] = useState(null);
  const [domainId, setDomainId] = useState("");
  const [slugValid, isSlugValid] = useState(true);

  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [loading, isLoading] = useState(false);
  const [slugMessage, setSlugMessage] = useState("");
  const [passwordEnabled, setPasswordEnabled] = useState(null);

  useEffect(() => {
    if (data) {
      setPassword(data?.data?.passwordEnabled);
      setPasswordEnabled(data?.data?.passwordEnabled);
    }
  }, [data]);

  const handleToastChange = () => {
    setToastVisible(false);
  };

  const handleVisiblePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleVisibleConfirmPassword = () => {
    setCnfPasswordVisible(!cnfPasswordVisible);
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setPasswordL(value);
    console.log(passwordL);
    const valid = validatePassword(value);
    const validc = validateConfirmPassword(confirmPassword);
    setIsValidConfirmPassword(validc);
    setIsValidPassword(valid);
    if (valid || validc) {
      if (valid) {
        setPasswordMessage("");
        if (validc === false && confirmPassword.length > 0) {
          setConfirmPasswordMessage(
            "Confirm password does not match with the password"
          );
        } else if (validc === false && confirmPassword.length === 0) {
          setConfirmPasswordMessage("Please provide confirm password");
        }
      }
      if (validc) {
        setConfirmPasswordMessage("");
      }
    } else {
      if (valid && !validc) {
        if (confirmPassword.length < 1) {
          setConfirmPasswordMessage("Please provide confirm password");
        } else {
          setConfirmPasswordMessage(
            "Confirm password does not match with the password"
          );
        }
      }
      setPasswordMessage("Please provide password");
    }
  };

  const validatePassword = (password) => {
    return password.length > 0;
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    return (
      password === confirmPassword &&
      password.length > 0 &&
      confirmPassword.length > 0
    );
  };

  const handleConfirmPassword = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    const valid = validateConfirmPassword(passwordL, value);
    setIsValidConfirmPassword(valid);
    if (valid) {
      setConfirmPasswordMessage("");
    } else {
      if (value.length < 1) {
        setConfirmPasswordMessage("Please provide confirm password.");
      } else {
        setConfirmPasswordMessage(
          "Confirm password does not match with password."
        );
      }
    }
  };

  /**
   *
   * VALIDATIONS
   *
   * destination url -- valid
   * title -- valid
   * if(utm) --> all 4 valid
   * if(password) --> password confirm password valid
   *
   *
   * if (isValid && isValidTitle) {
      if (utm || password) {
        if (utm && !password) {
          if (isValidCampaign && isValidUTM && isValidSource && isValidMedium) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        } else if (password && !utm) {
          if (isValidPassword && isValidConfirmPassword) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        } else {
          if (
            isValidCampaign &&
            isValidUTM &&
            isValidSource &&
            isValidMedium &&
            isValidPassword &&
            isValidConfirmPassword
          ) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        }
      } else {
        isLoading(false);
        createLinkApi();
      }
    } else {
    }
   *
   */
  const updateLinkApi = async () => {
    isLoading(true);
    const data = {
      linkId: id,
      passwordEnabled: password ? true : false,
      password: passwordL,
    };

    console.log("data sent : " + JSON.stringify(data));

    const token = JSON.parse(localStorage.getItem("token"));

    const config = {
      method: "post",
      url: "/atz/links/update-link",
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
      data: { data },
    };

    try {
      const updateLinkResponse = await axiosInstance(config);
      isLoading(false);

      console.log(updateLinkResponse.status);
      if (updateLinkResponse.status === 200) {
        setToastVisible(true);
        setCountChild((prev) => prev + 1);
        setPasswordL("");
        setIsValidPassword(null);
        setConfirmPassword("");
        setIsValidConfirmPassword(null);
        setTimeout(() => {
          setToastVisible(false);
        }, 5000);
      }
    } catch (e) {
      isLoading(false);
      if (e.response.status === 404) {
        console.log(e.response);
        setToastVisible(false);
      } else {
        console.error(e);
      }
    }
  };
  const handleUpdatePassword = () => {
    if (password) {
      if (passwordL.length < 1 && confirmPassword.length < 1) {
        setIsValidPassword(false);
        setIsValidConfirmPassword(false);
        setPasswordMessage("Please provide password");
        setConfirmPasswordMessage("Please provide confirm password");
      } else {
        if (passwordL.length < 1) {
          setIsValidPassword(false);
          setPasswordMessage("Please provide password");
        } else if (confirmPassword.length < 1) {
          setIsValidConfirmPassword(false);
          setConfirmPasswordMessage("Please provide confirm password");
        }
      }
    }
    if (
      password &&
      isValidPassword &&
      isValidConfirmPassword &&
      passwordL === confirmPassword
    ) {
      updateLinkApi();
    }
    if (passwordEnabled) {
      if (
        password &&
        isValidPassword &&
        isValidConfirmPassword &&
        passwordL === confirmPassword
      ) {
        updateLinkApi();
      } else if (!password) {
        updateLinkApi();
      }
    }
  };
  const togglePassword = () => {
    setPassword(!password);
  };

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-56 sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4  rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800">
          <SmallLoader />
        </div>
      )}
      {!loading && (
        <div>
          <div className=" sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4  rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
            {/* <!-- Page Header --> */}

            <div className="flex flex-col py-2 pl-1">
              {/* top section */}

              {/* end top section */}

              {/* mid section */}
              <div className="flex flex-col md:flex-row justify-between items-center sm:items-baseline border-neutral-200 rounded-md sm:px-4 px-2 ">
                <div className="flex flex-col flex-1">
                  <div className="flex flex-1 justify-between">
                    <div className="text-base font-[500]">Manage Password</div>
                    {(password || passwordEnabled) && (
                      <div>
                        <button
                          className="bg-[#019ca2] text-white py-2 p-4 rounded-md hover:bg-[#46aaad] text-xs font-semibold"
                          onClick={handleUpdatePassword}
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col flex-1">
                    <div className="py-1">
                      <div className="py-1 mt-3">
                        {passwordEnabled && password && (
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              checked
                              onClick={togglePassword}
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                            />
                            <div className="absolute md:relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                            <span className="sm:ms-3 ms-16  text-sm font-medium text-gray-500 dark:text-gray-300">
                              Enable / Disable Password Protection
                            </span>
                          </label>
                        )}
                        {(!passwordEnabled || !password) && (
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              onClick={togglePassword}
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                            />
                            <div className="absolute md:relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                            <span className="sm:ms-3 ms-16  text-sm font-medium text-gray-500 dark:text-gray-300">
                              Enable Password Protection
                            </span>
                          </label>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${
                        password
                          ? `flex flex-col mt-2 pb-2 border-neutral-200  dark:border-neutral-600`
                          : `hidden`
                      }`}
                    >
                      <div className="my-3 font-semibold">
                        <div className="flex flex-col space-y-0.5 relative">
                          <div className="relative flex-1">
                            <input
                              type={`${passwordVisible ? `text` : `password`}`}
                              id="hs-floating-input-password0"
                              className={`peer p-3 pr-[72px] block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                passwordL.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none ${
                                isValidPassword === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidPassword === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 dark:border-neutral-700 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="Password"
                              value={passwordL}
                              onChange={handleChangePassword}
                            />
                            <label
                              htmlFor="hs-floating-input-password"
                              className={`absolute top-0 start-0 font-bold p-4 px-3 h-full peer-focus:text-[10px] ${
                                passwordL.length > 0
                                  ? "pt-4 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                  : "text-[9px] peer-focus:text-[9px]"
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              {passwordEnabled && "SET NEW PASSWORD"}
                              {!passwordEnabled && "PASSWORD"}
                            </label>

                            {passwordL.length > 0 && (
                              <button
                                onClick={handleVisiblePassword}
                                className="absolute top-1/2 right-10 transform -translate-y-1/2 text-neutral-800 dark:text-neutral-100"
                              >
                                {!passwordVisible && (
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                                {passwordVisible && (
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </button>
                            )}

                            {isValidPassword === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidPassword === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidPassword === false && (
                            <p
                              className="text-[12px] font-light text-red-600"
                              id="hs-validation-password-error-helper"
                            >
                              {passwordMessage}
                            </p>
                          )}
                          {isValidPassword === true && (
                            <p
                              className="text-[12px] font-light text-green-600"
                              id="hs-validation-password-success-helper"
                            ></p>
                          )}
                        </div>
                      </div>
                      <div className="my-3 font-semibold">
                        <div className="flex flex-col space-y-0.5 relative">
                          <div className="relative flex-1">
                            <input
                              type={`${
                                cnfPasswordVisible ? `text` : `password`
                              }`}
                              id="hs-floating-input-confirm-password11"
                              className={`peer p-3 pr-[72px] block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                confirmPassword.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none ${
                                isValidConfirmPassword === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidConfirmPassword === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={handleConfirmPassword}
                            />
                            <label
                              htmlFor="hs-floating-input-confirm-password"
                              className={`absolute top-0 start-0 font-bold p-4 px-3 h-full peer-focus:text-[10px] ${
                                confirmPassword.length > 0
                                  ? "pt-4 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                  : "text-[9px] peer-focus:text-[9px]"
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              {passwordEnabled && "CONFIRM NEW PASSWORD"}
                              {!passwordEnabled && "CONFIRM PASSWORD"}
                            </label>

                            {confirmPassword.length > 0 && (
                              <button
                                onClick={handleVisibleConfirmPassword}
                                className="absolute top-1/2 right-10 transform -translate-y-1/2 text-neutral-800 dark:text-neutral-100"
                              >
                                {!cnfPasswordVisible && (
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                                {cnfPasswordVisible && (
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </button>
                            )}

                            {isValidConfirmPassword === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}
                            {isValidConfirmPassword === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidConfirmPassword === false && (
                            <p
                              className="text-[12px]  font-light text-red-600"
                              id="hs-validation-password-error-helper"
                            >
                              {confirmPasswordMessage}
                            </p>
                          )}
                          {isValidConfirmPassword === true && (
                            <p
                              className="text-[12px] font-light text-green-600"
                              id="hs-validation-password-success-helper"
                            ></p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end mid section */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkDetailsTemp4;
