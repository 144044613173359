import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import DashboardFooter from "../Dashboard/DashboardFooter";

import { useLocation, useParams } from "react-router-dom";
import SmallLoader from "../subcomponents/SmallLoader";
import axios from "axios";
import QrCard from "./QrCard";
import { DateRangePicker } from "react-date-range";
import NoLink from "../Links/NoLink";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const QrDynamic = () => {
  const location = useLocation();

  const [linksCount, setLinksCount] = useState(null);
  const [qrData, setQrData] = useState([]);
  const [loadingQrs, setLoadingQrs] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [qr, setQr] = useState({});
  const [loadingQr, setLoadingQr] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [DOMAINS, SETDOMAINS] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedRandomOption, setSelectedRandomOption] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [shortenUrl, setShortenUrl] = useState("");
  const [copy, setCopy] = useState("Copy");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const id = queryParams.get("id");
  useEffect(() => {
    const getDomains = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/domain/get-domains`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;

        SETDOMAINS(data);
        setAvailableOptions(data);
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getDomains();
  }, []);
  useEffect(() => {
    const getCount = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/qr-count`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;

        if (response.status === 200) {
          setLinksCount(data);
        }
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getCount();
  }, []);
  useEffect(() => {
    const getQrs = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          index: 0,
          itemsPerIndex: 10,
          search: searchInput ? searchInput : "",
          fromDate: "",
          toDate: "",
          domainIds: ["All"],
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-codes`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLoadingQrs(true);
        const response = await axios(config);
        const data = response.data.data;

        if (response.status === 200) {
          console.log(data);
          setQrData(data.qrCodes);
          setLoadingQrs(false);
        }
      } catch (error) {
        setLoadingQrs(false);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getQrs();
  }, [searchInput]);
  useEffect(() => {
    const getQr = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          qrCodeId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-code`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLoadingQr(true);
        const response = await axios(config);
        const data = response.data.data;

        if (response.status === 200) {
          setNotFound(false);
          console.log(data);
          setQr(data);
          setLoadingQr(false);
          setShortenUrl(data.shortenUrl);
          setImageUrl(data.qrCodeUrl);
        }
      } catch (error) {
        setLoadingQr(false);
        if (error.response.status === 404) {
          setNotFound(true);
        }
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getQr();
  }, []);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  useEffect(() => {
    document.getElementById("failureButton").click();
    // const handleLoad = () => {
    //   const failureButton = document.getElementById("failureButton");
    //   if (failureButton) {
    //     failureButton.click(); // Programmatically trigger the button click
    //   }
    // };

    // // Attach the load event listener to the window
    // window.addEventListener("load", handleLoad);

    // // Cleanup function to remove event listener on component unmount
    // return () => {
    //   window.removeEventListener("load", handleLoad);
    // };
  }, []);
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const { startDate, endDate } = selectionRange;

  const handleSelect = (ranges) => {
    console.log(startDate.toISOString());
    console.log(endDate.toISOString());
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
    setSelectionRange(ranges.selection);
  };
  const handleDateFilter = () => {
    setDateFilter(true);
  };
  const handleApplyFilter = () => {
    const getQrs = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        return;
      }
      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          return;
        }
      } catch (e) {
        console.log("failed to parse token data");
      }
      const requestData = {
        data: {
          index: 0, // MANDATORY
          itemsPerIndex: 10, // MANDATORY
          search: searchInput,
          fromDate: dateFilter
            ? startDate
              ? startDate.toISOString()
              : ""
            : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT
          toDate: dateFilter ? (endDate ? endDate.toISOString() : "") : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT,
          domainIds: selectedDomains ? selectedDomains : "All",
          // domainIds: selectedDomains ? selectedDomains : "All", // OPTIONAL ID FROM /get-domains API
        },
      };

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-codes`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingQrs(true);
        const response = await axios(config);
        // console.log(response.data.data);
        const linkk = response.data.data.links;
        // setTotalFilteredRecords(response.data.data.recordsTotal);
        // console.log(linkk);
        if (response.status === 200) {
          setSearchNotFound(false);
          setNotFound(false);
          setLoadingQrs(false);
          setQrData(response.data.data.qrCodes);
          // setRecordsFound(true);
          // setLoading(false);
        }
        // console.log(links);
        // setFilterLoading(false);
      } catch (error) {
        // setFilterLoading(false);
        setSearchNotFound(true);
        setLoadingQrs(false);
        if (error.response.status === 404) {
          setTimeout(() => {
            setNotFound(true);
            // setTotalFilteredRecords(0);
            // setRecordsFound(false);
          }, 500);
          // alert("not found");
        }

        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      } finally {
      }
    };
    getQrs();
  };
  const handleOptionClick = (option) => {
    setSelectedRandomOption((prev) => [...prev, option]);
    setAvailableOptions((prev) => prev.filter((item) => item.id !== option.id));
    setSelectedDomains((prev) => [...prev, option.id]);
    // setDomainModal(false);
    // Make the API call with the selected option's id (if needed)
    console.log("Selected Domain ID:", option.id);
  };

  const removePill = (option) => {
    setSelectedRandomOption((prev) =>
      prev.filter((item) => item.id !== option.id)
    );
    setSelectedDomains((prev) => prev.filter((id) => id !== option.id));
    setAvailableOptions((prev) => [...prev, option]);
  };
  const copyContent = async (e) => {
    try {
      await navigator.clipboard.writeText(shortenUrl);
      setCopy("Copied!");
      setTimeout(() => {
        setCopy("Copy");
      }, 2000);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const handleDownload = async () => {
    try {
      const response = await fetch(`${imageUrl}`);

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create an object URL from the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger download
      var link = document.createElement("a");
      link.href = url;
      link.download = `${shortenUrl
        .slice(8, shortenUrl.length)
        .replace("/", "_")}.png`; // Add the correct file extension
      link.click();
      logEvent(analytics, `CTA_QR_CODES_DOWNLOAD_SUCCESS`);

      // Clean up the object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  return (
    <>
      <div
        id="hs-scale-animation-modal1"
        className="hs-overlay [--overlay-backdrop:static] size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="hs-scale-animation-modal1-label"
      >
        <div className="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-md sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div className="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                {!notFound && "Your Atz QR code is ready"}
                {notFound && "Invalid QR code id"}
              </h3>

              {/* <!-- End Header --> */}
              <a
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                id="hs-scale-animation-modal1"
                href="/qr-code"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </a>
            </div>
            <div
              className="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100 min-h-96">
                {notFound && (
                  <div>
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      className="size-10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                          stroke="none"
                          fillRule="evenodd"
                          fill="#ff0000"
                        ></path>
                      </g>
                    </svg>
                  </div>
                )}
                {loadingQr && (
                  <div className="flex flex-1 min-h-56 justify-center items-center">
                    <SmallLoader />
                  </div>
                )}
                {!loadingQr && !notFound && qr && (
                  <div className="flex flex-col flex-1 justify-evenly items-center">
                    <div className="text-sm font-semibold ">
                      Scan below image to preview the code
                    </div>
                    <div className="text-xs flex text-center px-5">
                      <img
                        src={qr?.qrCodeUrl}
                        className="size-64 border p-4 border-gray-300 dark:border-neutral-700"
                        alt="qr-code-image"
                      />
                    </div>
                    <div className="text-sm font-semibold -mb-1 flex justify-between gap-x-1 items-center">
                      <a
                        className="text-primary-800 cursor-pointer hover:underline"
                        href={qr?.shortenUrl}
                      >
                        {qr?.shortenUrl}
                      </a>
                      <span className="cursor-pointer" onClick={copyContent}>
                        <svg
                          viewBox="0 0 24 24"
                          className="size-4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M21 8C21 6.34315 19.6569 5 18 5H10C8.34315 5 7 6.34315 7 8V20C7 21.6569 8.34315 23 10 23H18C19.6569 23 21 21.6569 21 20V8ZM19 8C19 7.44772 18.5523 7 18 7H10C9.44772 7 9 7.44772 9 8V20C9 20.5523 9.44772 21 10 21H18C18.5523 21 19 20.5523 19 20V8Z"
                              fill="currentColor"
                            ></path>{" "}
                            <path
                              d="M6 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H6C4.34315 1 3 2.34315 3 4V18C3 18.5523 3.44772 19 4 19C4.55228 19 5 18.5523 5 18V4C5 3.44772 5.44772 3 6 3Z"
                              fill="currentColor"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="text-sm font-semibold -mb-1 flex justify-between gap-x-2 items-center mt-4">
                      <button
                        className="border rounded-lg py-1.5 px-6 text-white bg-primary-800 "
                        onClick={handleDownload}
                      >
                        Download PNG
                      </button>
                      <button
                        className="border rounded-lg py-1.5 px-6 text-primary-800 hover:bg-primary-800 hover:text-white"
                        onClick={() =>
                          (window.location.href = `/qr-code/customize/code?id=${id}`)
                        }
                      >
                        Customize
                      </button>
                    </div>
                  </div>
                )}
                {!loadingQr && notFound && (
                  <>
                    <div className="text-sm font-semibold -mb-1">
                      Looks like the QR code you are trying to access does not
                      exists.
                    </div>
                    <div className="text-xs flex text-center px-5">
                      Please provide a valid QR code id.
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-none dark:border-neutral-700">
              <button
                type="button"
                className="hidden py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-primary-400 hover:bg-primary-600 text-primary-600 hover:text-white dark:hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                // onClick={() => (window.location.href = "/billing/ai-credits")}
              ></button>
            </div>
          </div>
        </div>
      </div>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div className="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="flex justify-between">
            <div className="text-3xl font-bold">
              QR Codes
              {linksCount && <span> ({linksCount})</span>}
            </div>
            <div className="">
              <button
                type="button"
                className="px-10 py-2 bg-[#019ca2] hover:bg-[#27797c] text-neutral-50 font-semibold rounded-md"
                onClick={() => (window.location.href = "/create-qr")}
              >
                + Create new QR
              </button>
            </div>
          </div>
          <div className="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
            <div className="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5">
              <div>
                {/* <!-- Search Input --> */}
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                    <svg
                      className="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <path d="m21 21-4.3-4.3" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    value={searchInput}
                    onChange={handleSearchInput}
                    className="input-style py-[7px] px-3 ps-10 block w-full bg-inherit border border-neutral-200
                              dark:border-neutral-700 focus:border-neutral-200
                              dark:focus:border-neutral-700
                              focus:ring-0 rounded-lg text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:border-neutral-400"
                    placeholder="Search links with titles"
                  />
                </div>
                {/* <!-- End Search Input --> */}
              </div>
              {/* <!-- End Col --> */}

              <div className="flex md:justify-end items-center gap-x-2">
                {/* <!-- Import / Export Dropdown --> */}
                <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                  {/* <!-- Import / Export Button --> */}

                  {/* <!-- End Import / Export Button --> */}

                  {/* <!-- Dropdown --> */}
                  <div
                    className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
                    aria-labelledby="hs-pro-dptied"
                  >
                    <div className="p-1">
                      <button
                        type="button"
                        className="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                        data-hs-overlay="#hs-pro-dicm"
                      >
                        <svg
                          className="flex-shrink-0 mt-0.5 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="7 10 12 15 17 10" />
                          <line x1="12" x2="12" y1="15" y2="3" />
                        </svg>
                        Import contacts
                      </button>
                      <button
                        type="button"
                        className="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 "
                        data-hs-overlay="#hs-pro-decm"
                      >
                        <svg
                          className="flex-shrink-0 mt-0.5 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="17 8 12 3 7 8" />
                          <line x1="12" x2="12" y1="3" y2="15" />
                        </svg>
                        Export contacts
                      </button>
                    </div>
                  </div>
                  {/* <!-- End Dropdown --> */}
                </div>
                {/* <!-- End Import / Export Dropdown --> */}

                {/* <!-- Download Dropdown --> */}
                <button
                  type="button"
                  className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hidden"
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  aria-controls="hs-offcanvas-right"
                  data-hs-overlay="#hs-offcanvas-right"
                >
                  <svg
                    className="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="21" x2="14" y1="4" y2="4" />
                    <line x1="10" x2="3" y1="4" y2="4" />
                    <line x1="21" x2="12" y1="12" y2="12" />
                    <line x1="8" x2="3" y1="12" y2="12" />
                    <line x1="21" x2="16" y1="20" y2="20" />
                    <line x1="12" x2="3" y1="20" y2="20" />
                    <line x1="14" x2="14" y1="2" y2="6" />
                    <line x1="8" x2="8" y1="10" y2="14" />
                    <line x1="16" x2="16" y1="18" y2="22" />
                  </svg>
                  Custom Filter
                </button>
                <button
                  type="button"
                  className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  data-hs-overlay="#hs-overlay-right"
                >
                  <svg
                    className="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="21" x2="14" y1="4" y2="4" />
                    <line x1="10" x2="3" y1="4" y2="4" />
                    <line x1="21" x2="12" y1="12" y2="12" />
                    <line x1="8" x2="3" y1="12" y2="12" />
                    <line x1="21" x2="16" y1="20" y2="20" />
                    <line x1="12" x2="3" y1="20" y2="20" />
                    <line x1="14" x2="14" y1="2" y2="6" />
                    <line x1="8" x2="8" y1="10" y2="14" />
                    <line x1="16" x2="16" y1="18" y2="22" />
                  </svg>
                  Filter
                  <span className="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full dark:bg-neutral-500">
                    2
                  </span>
                </button>

                <div
                  id="hs-overlay-right"
                  className="hs-overlay hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform overflow-scroll h-full w-full sm:w-auto z-[80] bg-white border-s dark:bg-neutral-800 dark:border-neutral-700"
                  tabIndex="-1"
                >
                  <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3 className="font-bold text-gray-800 dark:text-white">
                      Filters
                    </h3>
                    <button
                      type="button"
                      className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                      data-hs-overlay="#hs-overlay-right"
                    >
                      <span className="sr-only">Close modal</span>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-4 dark:text-white">
                    <div className="space-y-2">
                      <div className="border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                        <div className="p-4 relative">
                          <div className="p-2">Domains</div>
                          <input
                            className="min-w-full overflow-auto rounded-md border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 focus:border-gray-200 dark:focus:border-neutral-700 focus:ring-0 dark:text-white dark:placeholder:text-neutral-300 cursor-pointer"
                            placeholder="Select domain"
                            // onClick={handleInputClick}
                          />
                          {/* {domainModal && (
                                      <div className="mt-1 flex flex-1 flex-col rounded-md border border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 z-[100]">
                                        {availableOptions.map((option) => (
                                          <div
                                            key={option.id}
                                            className="min-w-full border-b p-2 hover:bg-[#019ca2] dark:border-neutral-700 hover:text-white cursor-pointer"
                                            onClick={() =>
                                              handleOptionClick(option)
                                            }
                                          >
                                            {option.domain}
                                          </div>
                                        ))}
                                      </div>
                                    )} */}

                          {/* Pills */}
                          {/* <div className="relative py-2 dark:text-neutral-400 z-[80]">
                                      <div className="flex flex-wrap gap-x-2 gap-y-2">
                                        {selectedRandomOption.map((option) => (
                                          <div
                                            key={option.id}
                                            className="dark:bg-neutral-900 border border-gray-300 dark:border-neutral-700 rounded-full px-4 p-2 cursor-pointer gap-x-2 flex"
                                            onClick={() => removePill(option)}
                                          >
                                            <div>{option.domain}</div>
                                            <div>x</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div> */}
                          {/* Pills END */}
                        </div>
                      </div>

                      <div className="p-4 pb-0 flex flex-col  border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                        <span className="flex">Date</span>
                        <div
                          className="flex py-2 overflow-auto"
                          onClick={handleDateFilter}
                        >
                          <DateRangePicker
                            initialFocusedRange={null}
                            color="#019ca2"
                            rangeColors="019ca2"
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="p-4 font-semibold text-xs flex flex-1 gap-x-6">
                        <div className="flex flex-1">
                          <button
                            data-hs-overlay="#hs-overlay-right"
                            className="p-2 border w-full dark:border-neutral-600 border-gray-200 rounded-md hover:bg-neutral-300 dark:hover:bg-neutral-700"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="flex flex-1">
                          <button
                            data-hs-overlay="#hs-overlay-right"
                            className="p-2 bg-[#019ca2] w-full text-white rounded-md hover:bg-[#38afb4] "
                            onClick={handleApplyFilter}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- End Download Dropdown --> */}
              </div>
              {/* <!-- End Col --> */}
            </div>
            {loadingQrs && (
              <div className="min-h-96 flex flex-1 justify-center items-center">
                <SmallLoader />
              </div>
            )}
            {!loadingQrs &&
              !searchNotFound &&
              linksCount !== 0 &&
              qrData &&
              qrData?.map((qrData, key) => (
                <QrCard qrData={qrData} index={key} />
              ))}
            {searchNotFound && (
              <div>
                <NoLink noMatch={true} />
              </div>
            )}
            {!loadingQrs && linksCount === 0 && (
              <div className="text-sm sm:p-5 p-2">
                <div className="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                  <svg
                    className="w-48 mx-auto mb-4"
                    width="178"
                    height="90"
                    viewBox="0 0 178 90"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="27"
                      y="50.5"
                      width="124"
                      height="39"
                      rx="7.5"
                      fill="currentColor"
                      className="fill-white dark:fill-neutral-800"
                    />
                    <rect
                      x="27"
                      y="50.5"
                      width="124"
                      height="39"
                      rx="7.5"
                      stroke="currentColor"
                      className="stroke-gray-50 dark:stroke-neutral-700/10"
                    />
                    <rect
                      x="34.5"
                      y="58"
                      width="24"
                      height="24"
                      rx="4"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="66.5"
                      y="61"
                      width="60"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="66.5"
                      y="73"
                      width="77"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-50 dark:fill-neutral-700/30"
                    />
                    <rect
                      x="19.5"
                      y="28.5"
                      width="139"
                      height="39"
                      rx="7.5"
                      fill="currentColor"
                      className="fill-white dark:fill-neutral-800"
                    />
                    <rect
                      x="19.5"
                      y="28.5"
                      width="139"
                      height="39"
                      rx="7.5"
                      stroke="currentColor"
                      className="stroke-gray-100 dark:stroke-neutral-700/30"
                    />
                    <rect
                      x="27"
                      y="36"
                      width="24"
                      height="24"
                      rx="4"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <rect
                      x="59"
                      y="39"
                      width="60"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <rect
                      x="59"
                      y="51"
                      width="92"
                      height="6"
                      rx="3"
                      fill="currentColor"
                      className="fill-gray-100 dark:fill-neutral-700/70"
                    />
                    <g filter="url(#filter19)">
                      <rect
                        x="12"
                        y="6"
                        width="154"
                        height="40"
                        rx="8"
                        fill="currentColor"
                        className="fill-white dark:fill-neutral-800"
                        shape-rendering="crispEdges"
                      />
                      <rect
                        x="12.5"
                        y="6.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        stroke="currentColor"
                        className="stroke-gray-100 dark:stroke-neutral-700/60"
                        shape-rendering="crispEdges"
                      />
                      <rect
                        x="20"
                        y="14"
                        width="24"
                        height="24"
                        rx="4"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700 "
                      />
                      <rect
                        x="52"
                        y="17"
                        width="60"
                        height="6"
                        rx="3"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700"
                      />
                      <rect
                        x="52"
                        y="29"
                        width="106"
                        height="6"
                        rx="3"
                        fill="currentColor"
                        className="fill-gray-200 dark:fill-neutral-700"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter19"
                        x="0"
                        y="0"
                        width="178"
                        height="64"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="6" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1187_14810"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1187_14810"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>

                  <div className="max-w-sm mx-auto">
                    <p className="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                      Uh oh!
                    </p>
                    <p className="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                      It looks like you don't have any domains created yet.
                      Start by creating one.
                    </p>
                  </div>
                  <button
                    type="button"
                    className="border border-[#019ca2] hover:bg-[#019ca2] px-16 py-2 rounded-r-full rounded-l-full text-[#019ca2] hover:text-neutral-50 font-semibold"
                    onClick={() => (window.location.href = "/create-qr")}
                  >
                    + New QR
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default QrDynamic;
