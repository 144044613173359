import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
// import "preline/preline.js";
// import Home from "./components/Landing/Home";
import Callback from "./components/Callback";
import Dashboard from "./components/Dashboard/Dashboard";
import Logout from "./components/Logout";
import Login from "./components/Login";
import PrivateRoute from "./PrivateRoute";
import Link from "./components/Links/Link";
import CreateLink from "./components/Links/CreateLink";
import Error from "./components/Error";
import CreateNewDomain from "./components/Links/CreateNewDomain";
import AiComponent from "./components/Links/AiComponent";
// import "preline/preline";
import GenAiComp from "./components/GenAiComp.js";
import GenAiMain from "./components/GenAi/GenAiMain.js";
import Analytics from "./components/Analytics/Analytics.js";
// import Gallery from "./components/Gallery/Gallery.js";
import QR from "./components/QR/QR.js";
// import Analytics1 from "./components/Services/Analytics.js";
import AiCredits from "./components/AiCredits/AiCredits.js";
import Overview from "./components/Billing/Overview.js";
import Subscriptions from "./components/Billing/Subscriptions.js";
import Transactions from "./components/Billing/Transactions.js";
import Domain from "./components/Domains/Domain.js";
import DomainSuccess from "./components/Domains/DomainSuccess.js";
// import GalleryService from "./components/Services/Gallery.js";
import axios from "axios";
// import SessionExpired from "./components/SessionExpired.js";
import CreateQr from "./components/QR/CreateQr.js";
import Success from "./components/Paypal/Payments/Success.js";
import Failure from "./components/Paypal/Payments/Failure.js";
import QrDynamic from "./components/QR/QrDynamic.js";
import QrEdit from "./components/QR/QrEdit.js";
import CustomizeQr from "./components/QR/CustomizeQr.js";
import Invoice from "./components/Billing/Invoice.js";
import SubscriptionSuccess from "./components/Paypal/Payments/SubscriptionSuccess.js";
import SubscriptionCancel from "./components/Paypal/Payments/SubscriptionCancel.js";
import InvoiceSub from "./components/Billing/InvoiceSub.js";
import AnalyticsSuper from "./components/Superadmin/Analytics.js";
import DomainRequests from "./components/Superadmin/DomainRequests.js";
import SuperPayments from "./components/Superadmin/SuperPayments.js";
import SuperOverview from "./components/Superadmin/SuperOverview.js";
// import Links1 from "./components/Services/Links1.js";
// import QR1 from "./components/Services/QR1.js";
// import BrandedDomain1 from "./components/Services/BrandedDomain1.js";
// import AtzAi1 from "./components/Services/AtzAi1.js";
// import AnalyticsNew from "./components/Services/Analytics1.js";
// import PrivacyPolicy from "./components/Services/PrivacyPolicy.js";
// import Terms from "./components/Services/Terms.js";
// import Cookies from "./components/Services/Cookies.js";
// import Pricing from "./components/Landing/Pricing.js";
import BlogLanding from "./components/Blogs/BlogLanding.js";
import BlogArticle from "./components/Blogs/BlogArticle.js";
const Links1 = lazy(() => import("./components/Services/Links1.js"));
const QR1 = lazy(() => import("./components/Services/QR1.js"));
const GalleryService = lazy(() => import("./components/Services/Gallery.js"));
const AtzAi1 = lazy(() => import("./components/Services/AtzAi1.js"));
const BrandedDomain1 = lazy(() =>
  import("./components/Services/BrandedDomain1.js")
);
const Pricing = lazy(() => import("./components/Landing/Pricing.js"));
const PrivacyPolicy = lazy(() =>
  import("./components/Services/PrivacyPolicy.js")
);
const Terms = lazy(() => import("./components/Services/Terms.js"));
const Cookies = lazy(() => import("./components/Services/Cookies.js"));
const SessionExpired = lazy(() => import("./components/SessionExpired.js"));
const AnalyticsNew = lazy(() => import("./components/Services/Analytics1.js"));
const Loader = lazy(() => import("./components/subcomponents/Loader.js"));

const Home1 = lazy(() => import("./components/Landing/Home1.js"));

function App() {
  useEffect(() => {
    // Interceptor for handling 401 Unauthorized errors
    const setupAxiosInterceptors = () => {
      // Function to refresh the token
      const refreshToken = async () => {
        const storedToken = JSON.parse(localStorage.getItem("token"));
        if (!storedToken || !storedToken.refresh_token) {
          throw new Error("No refresh token found");
        }
        const refreshTokenValue = storedToken.refresh_token;

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_GATEWAY_URL}/core/refresh-token`,
            {
              data: {
                clientName: "atz",
                refreshToken: refreshTokenValue,
              },
            }
          );

          // Store the new tokens
          const newToken = response.data.data;
          localStorage.setItem("token", JSON.stringify(newToken));
          return newToken.access_token; // Return the new access token
        } catch (error) {
          console.error("Failed to refresh token:", error);
          throw error;
        }
      };

      // Axios response interceptor to handle 401 errors globally
      axios.interceptors.response.use(
        (response) => {
          // If the response is successful, just return it
          return response;
        },
        async (error) => {
          const originalRequest = error.config;

          // Check if the error is a 401 and it's not already a retried request
          if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true; // Mark the request as retrying

            try {
              // Try to refresh the token
              const newAccessToken = await refreshToken();

              // Update the Authorization header with the new token
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;

              // Retry the original request with the new token
              return axios(originalRequest);
            } catch (refreshError) {
              console.error("Token refresh failed:", refreshError);

              // If refresh token failed, clear local storage and redirect to login
              localStorage.clear();
              window.location.href = "/session-expired";
              return Promise.reject(refreshError);
            }
          }

          // If it's a different error, just reject it
          return Promise.reject(error);
        }
      );
    };

    // Set up the interceptor on app mount
    setupAxiosInterceptors();
  }, []);

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Suspense fallback={<Loader />}>
            <Error />
          </Suspense>
        }
      />
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
          </Suspense>
        }
      />

      <Route
        path="/services/links"
        element={
          <Suspense fallback={<Loader />}>
            {/* <QR1 /> */}
            <Home1 />
          </Suspense>
        }
      />
      <Route
        path="/services/qr-code"
        element={
          <Suspense fallback={<Loader />}>
            {/* <QR1 /> */}
            <Home1 />
          </Suspense>
        }
      />
      <Route
        path="/services/gallery"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <GalleryService /> */}
          </Suspense>
        }
      />
      <Route
        path="/services/branded-domains"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <BrandedDomain1 /> */}
          </Suspense>
        }
      />
      <Route
        path="/services/atz-ai"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <AtzAi1 /> */}
          </Suspense>
        }
      />
      <Route
        path="/services/analytics"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <AnalyticsNew /> */}
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <PrivacyPolicy /> */}
          </Suspense>
        }
      />
      <Route
        path="/pricing"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <Pricing /> */}
          </Suspense>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <Terms /> */}
          </Suspense>
        }
      />
      <Route
        path="/cookies-policy"
        element={
          <Suspense fallback={<Loader />}>
            <Home1 />
            {/* <Cookies /> */}
          </Suspense>
        }
      />
      {/* <Route
        path="/blogs"
        element={
          <Suspense fallback={<Loader />}>
            <BlogLanding />
          </Suspense>
        }
      />
      <Route
        path="/blogs/article"
        element={
          <Suspense fallback={<Loader />}>
            <BlogArticle />
          </Suspense>
        }
      /> */}

      <Route path="/callback" element={<Callback />} />

      <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />

      <Route path="/links" element={<PrivateRoute element={Link} />} />
      <Route
        path="/create-new-domain"
        element={<PrivateRoute element={CreateNewDomain} />}
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/landing" element={<Home />} /> */}

      <Route
        path="/links-details"
        element={<PrivateRoute element={CreateNewDomain} />}
      />
      {/* <Route path="/links-details" element={<CreateNewDomain />} /> */}

      <Route
        path="/links-ai"
        element={<PrivateRoute element={AiComponent} />}
      />
      <Route path="/gen-ai" element={<PrivateRoute element={GenAiComp} />} />
      <Route path="/atz-ai" element={<PrivateRoute element={GenAiMain} />} />
      <Route
        path="/super-admin/analytics"
        element={<PrivateRoute element={AnalyticsSuper} />}
      />
      <Route
        path="/super-admin/domains"
        element={<PrivateRoute element={DomainRequests} />}
      />
      <Route
        path="/super-admin/payments"
        element={<PrivateRoute element={SuperPayments} />}
      />
      <Route
        path="/super-admin/overview"
        element={<PrivateRoute element={SuperOverview} />}
      />
      <Route
        path="/billing/ai-credits"
        element={<PrivateRoute element={AiCredits} />}
      />
      <Route
        path="/billing/ai-credits/invoice"
        element={<PrivateRoute element={Invoice} />}
      />
      <Route
        path="/billing/subscriptions/invoice"
        element={<PrivateRoute element={InvoiceSub} />}
      />
      <Route
        path="/billing/subscriptions"
        element={<PrivateRoute element={Subscriptions} />}
      />
      <Route
        path="/billing/overview"
        element={<PrivateRoute element={Overview} />}
      />
      <Route
        path="/billing/transactions"
        element={<PrivateRoute element={Transactions} />}
      />
      <Route path="/analytics" element={<PrivateRoute element={Analytics} />} />
      <Route path="/domains" element={<PrivateRoute element={Domain} />} />
      <Route
        path="/domains/config"
        element={<PrivateRoute element={DomainSuccess} />}
      />
      {/* <Route path="/genai" element={<GenAiMain />} /> */}
      <Route
        path="/create-link"
        element={<PrivateRoute element={CreateLink} />}
      />
      <Route
        path="/paypal/payment/success"
        element={<PrivateRoute element={Success} />}
      />
      <Route
        path="/paypal/subscription/success"
        element={<PrivateRoute element={SubscriptionSuccess} />}
      />
      <Route
        path="/paypal/subscription/cancel"
        element={<PrivateRoute element={SubscriptionCancel} />}
      />
      <Route
        path="/paypal/payment/cancel"
        element={<PrivateRoute element={Failure} />}
      />
      <Route
        path="/qr-code/code"
        element={<PrivateRoute element={QrDynamic} />}
      />
      <Route path="/create-qr" element={<CreateQr />} />
      {/* <Route path="/gallery" element={<Gallery />} />   */}
      <Route path="/qr-code" element={<PrivateRoute element={QR} />} />
      <Route
        path="/qr-code/edit/code"
        element={<PrivateRoute element={QrEdit} />}
      />
      <Route
        path="/qr-code/customize/code"
        element={<PrivateRoute element={CustomizeQr} />}
      />

      <Route
        path="/session-expired"
        element={
          <Suspense fallback={<Loader />}>
            <SessionExpired />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default App;

/*import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import Home from "./components/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "preline/preline.js";
import Callback from "./components/Callback";
import Dashboard from "./components/Dashboard";
import { IStaticMethods } from "preline/preline";
import Logout from "./components/Logout";

import Login from "./components/Login";
import PrivateRoute from "./PrivateRoute";

//before any component render
//check if token object exists

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
  {
    path: "/dashboard",
    element: <PrivateRoute element={Dashboard} />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
*/
