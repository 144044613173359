import React, { useEffect, useState } from "react";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardSidebar from "./Dashboard/DashboardSidebar";
import { useLocation } from "react-router-dom";
import axios from "axios";

const SubExpired = ({ plan }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleRenew = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-request`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          packageId: plan?.subscriptionId,
        },
      },
    };
    try {
      //   logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_RENEW`);
      setLoading(true);
      const response = await axios(config);
      console.log(response.data.data);

      // setPlanData(response.data.data.subscriptions);
      // setActivePlan(response.data.data.activeSubscription);
      if (response.status === 200) {
        // logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_RENEW_SUCCESS`);
        window.location.href = response.data.data;
      }
    } catch (e) {
      //   logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_RENEW_FAILED`);
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <div
        id={`hs-vertically-centered-modal-1`}
        className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabIndex="-1"
        aria-labelledby={`hs-vertically-centered-modal-1-label`}
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3
                id="hs-vertically-centered-modal-1-label"
                className="font-bold text-gray-800 dark:text-white text-base"
              >
                Renew Your Subscription
              </h3>
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay={`#hs-vertically-centered-modal-1`}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div className="p-4 overflow-y-auto">
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fillRule="evenodd"
                        fill="#019ca2"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1 text-center">
                  Renew now to continue enjoying uninterrupted access to all our
                  features and services.
                </div>
                <div className="text-xs flex  text-center px-5">
                  <span>
                    By renewing, you'll ensure that your benefits remain active
                    without any service disruptions.
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button
                type="button"
                className="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-primary-400 hover:bg-primary-600 text-primary-600 hover:text-white dark:hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                data-hs-overlay={`#hs-vertically-centered-modal-1`}
                onClick={handleRenew}
              >
                Renew Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <DashboardHeader />
      <DashboardSidebar />

      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div className="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
            <div className="flex flex-1 flex-col justify-center items-center min-h-[500px] gap-y-1">
              <svg
                viewBox="0 0 24 24"
                className="size-20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
                    stroke="#019ca2"
                    strokeWidth="1.5"
                  ></path>{" "}
                  <path
                    d="M7 4V2.5"
                    stroke="#019ca2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                  <path
                    d="M17 4V2.5"
                    stroke="#019ca2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                  <path
                    d="M9 14.5L10.5 13V17"
                    stroke="#019ca2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                  <path
                    d="M13 16V14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16Z"
                    stroke="#019ca2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                  <path
                    d="M2.5 9H21.5"
                    stroke="#019ca2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
              <div className="text-3xl font-semibold">
                Looks like your{" "}
                <span className="text-primary-900">subscription</span> has
                expired
              </div>
              <div className="text-sm font-semibold">
                Don't worry we have all the analytics in place, it will be
                available to you as soon as you renew your plan.
              </div>
              <div className="text-base font-semibold">
                <span className="text-primary-900">Renew</span> now to continue
                using the Atz platform!
              </div>
              <button
                className="py-1.5 px-4 inline-flex items-center gap-x-4  text-xs font-medium rounded-full  dark:text-white text-primary-900  border border-primary-500 dark:border-neutral-600 mr-3 hover:bg-primary-900 dark:hover:bg-primary-500 hover:text-white dark:hover:text-white"
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls={`hs-vertically-centered-modal-1`}
                data-hs-overlay={`#hs-vertically-centered-modal-1`}
              >
                <span className="text-xs relative -ms-1 space-x-1">
                  <span className="ps-2.5 ">Renew Subscription</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SubExpired;
