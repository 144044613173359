import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../instances/axiosInstance";
import Loader from "./subcomponents/Loader";
const Logout = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const redirect = () => {
    window.location.href = process.env.REACT_APP_ATZ_WEBSITE_URL;
  };

  useEffect(() => {
    const revokeTokenAPI = async () => {
      const data = JSON.stringify({
        data: {
          clientName: "atz",
        },
      });
      const token = JSON.parse(localStorage.getItem("token"));
      // console.log(token);
      const { access_token: accessToken } = token;

      const config = {
        method: "post",
        url: "/secured/revoke-token",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };

      try {
        setLoading(true);
        const revokeResponse = await axiosInstance(config);
      } catch (error) {
        console.log(error);
      } finally {
        localStorage.clear();
        redirect();
        setLoading(false);
      }
    };
    revokeTokenAPI();
  }, []);

  return <div>{loading && <Loader aiLoading={false} />}</div>;
};

export default Logout;
