import React, { useEffect, useRef, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";

import { useLocation, useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import SmallLoader from "../subcomponents/SmallLoader";
import LinkDetailCard from "../subcomponents/LinkDetailCard";
const CustomizeQr = () => {
  const canvasRef = useRef(null);
  const [logoVisible, setLogoVisible] = useState(true);
  const navigate = useNavigate();
  const [loadingNext, setLoadingNext] = useState(false);
  const [qrStyle, setQrStyle] = useState(null);
  const [activeStyle, setActiveStyle] = useState(null);
  const [activeCorner, setActiveCorner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [qr, setQr] = useState(null);

  const [tempUrl, setTempUrl] = useState("");
  const [tempLinkId, setTempLinkId] = useState("");
  const [qrCorner, setQrCorner] = useState({
    outer: [0],
    inner: [0],
  });

  const [qrColor, setQrColor] = useState("#000");
  const [qrBgColor, setQrBgColor] = useState("#fff");

  const [activePage, setActivePage] = useState(0);
  const [activeFgColor, setActiveFgColor] = useState(null);
  const [activeBgColor, setActiveBgColor] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);

  const [qrFile, setQRFile] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  useEffect(() => {
    const getQr = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          qrCodeId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-code`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLoading(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);

        if (response.status === 200) {
          setQr(data);
          setTempUrl(response.data.data.shortenUrl);
          setQrStyle(response.data.data.style);
          setActiveStyle(response.data.data.style);
          setActiveCorner(response.data.data.cornerType);
          setQrColor(response.data.data.foregroundColor);
          setQrBgColor(response.data.data.backgroundColor);
          setActiveFgColor(response.data.data.foregroundColor);
          setActiveBgColor(response.data.data.backgroundColor);
          setLogoVisible(response.data.data.displayBrandLogo);
          if (response.data.data.cornerType == "circle") {
            setQrCorner({
              outer: [100, 100, 100, 100],
              inner: [100, 100, 100, 100],
            });
          } else if (response.data.data.cornerType == "instagram") {
            setQrCorner({
              outer: [25, 25, 25, 25],
              inner: [100, 100, 100, 100],
            });
          } else if (response.data.data.cornerType == "tleftbright-circle") {
            setQrCorner({
              outer: [0, 100, 0, 100],
              inner: [100, 100, 100, 100],
            });
          } else if (response.data.data.cornerType == "topleft-circle") {
            setQrCorner({
              outer: [0, 100, 100, 100],
              inner: [100, 100, 100, 100],
            });
          } else if (response.data.data.cornerType == "topleft-square") {
            setQrCorner({
              outer: [0, 100, 100, 100],
              inner: [0],
            });
          } else if (response.data.data.cornerType == "square") {
            setQrCorner({
              outer: [0],
              inner: [0],
            });
          } else if (response.data.data.cornerType == "inner-circle") {
            setQrCorner({
              outer: [0, 0, 0, 0],
              inner: [100, 100, 100, 100],
            });
          } else if (response.data.data.cornerType == "outer-circle") {
            setQrCorner({
              outer: [100, 100, 100, 100],
              inner: [0, 0, 0, 0],
            });
          }
          setLoading(false);
        }
      } catch (error) {
        window.location.href = "/qr-code";
        if (error.response.status === 404) {
        }
        setLoading(false);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getQr();
  }, []);
  useEffect(() => {
    const getSubscription = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/active-subscriptions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        // setLoading(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);

        if (response.status === 200) {
          setActiveSubscription(response.data.data);
        }
      } catch (error) {
        if (error.response.status === 404) {
        }

        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getSubscription();
  }, []);

  const handleQR = () => {
    const canvas = canvasRef.current?.querySelector("canvas");

    if (canvas) {
      // Convert canvas to Blob (file-like object)
      canvas.toBlob((blob) => {
        if (blob) {
          // Convert Blob to File if you need it as a file object
          const file = new File([blob], "qr-code.png", { type: "image/png" });
          setQRFile(file);

          // Example: Uploading the file or doing something else with it
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.download = "qr-code.png"; // Set the file name
          link.click(); // Programmatically click the link to trigger download
          URL.revokeObjectURL(link.href); // Clean up
          console.log(file);
        }
      }, "image/png");
    }
  };

  const handleCustomize = async () => {
    var bodyFormData = new FormData();
    const canvas = canvasRef.current?.querySelector("canvas");

    if (!canvas) {
      console.log("Canvas not found");
      return;
    }

    // Use a promise to handle the asynchronous nature of canvas.toBlob
    console.log(tempUrl.slice(8, tempUrl.length).replace("/", "_"));
    const file = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File(
            [blob],
            `${tempUrl.slice(8, tempUrl.length).replace("/", "_") + "1"}.png`,
            {
              type: "image/png",
            }
          );
          resolve(file); // Resolves the promise with the file object
        } else {
          resolve(null); // Resolve with null if blob is not created
        }
      }, "image/png");
    });

    if (!file) {
      console.log("Failed to create file from canvas");
      return;
    }

    console.log(file);

    bodyFormData.append("file", file); // Attach the file to FormData

    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const requestData = {
      data: {
        style: qrStyle,
        cornerType: activeCorner,
        foregroundColor: qrColor,
        backgroundColor: qrBgColor,
        displayBrandLogo: logoVisible,
        qrCodeId: id,
      },
    };

    bodyFormData.append("data", JSON.stringify(requestData));

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/customize-qr-code`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData,
    };

    try {
      setLoadingNext(true);
      console.log(JSON.stringify(bodyFormData));
      const response = await axios(config);
      const data = response.data.data;

      if (response.status === 201) {
        window.location.href = `/qr-code/code?id=${data.id}`;
      }
    } catch (error) {
      setLoadingNext(false);
      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
      // setObj({ error: true });
    }
  };

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, activePage]);

  const handleReset = () => {
    setQrColor("#000");
    setQrCorner("square");
    setQrBgColor("#fff");
    setQrStyle("square");
    setActiveCorner("square");
    setActiveStyle("square");
  };

  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      {loadingNext && (
        <main id="content " className="lg:ps-[260px]  pt-[59px]">
          <div className="flex flex-1 flex-col pb-[59px] min-h-screen justify-center items-center">
            <SmallLoader />
          </div>
        </main>
      )}
      {!loadingNext && (
        <main id="content " className="lg:ps-[260px] pt-[59px]">
          <div className="w-full p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5 mb-4">
            {/* <!-- Stepper --> */}
            <div className="flex justify-between">
              <div className="text-3xl font-bold dark:text-neutral-300">
                Customize your QR code
                {/* {linksCount && <span> ({linksCount})</span>} */}
              </div>
            </div>

            <div className="p-4 sm:p-5 sm:py-4 border-[0.5px] space-y-5 rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
              {qr && (
                <div className="flex flex-col sm:pr-4">
                  <LinkDetailCard
                    linkDetails={qr}
                    clickCounts={qr?.clickCount}
                  />
                </div>
              )}
              <div className="flex flex-1 flex-col md:flex-row gap-y-6 sm:gap-y-0">
                <div className="flex-1">
                  <div className="flex flex-1 flex-col gap-y-6">
                    <div className="border dark:border-neutral-700 rounded-xl ">
                      <div className="flex flex-col sm:flex-row justify-between items-center gap-x-5 border-b dark:border-b-neutral-700">
                        <h2 className="p-4 inline-block text-xl font-semibold text-gray-800 dark:text-neutral-200 ">
                          You can customize your QR code here!
                        </h2>
                      </div>
                      <div className="p-4 space-y-2">
                        <h1 className="text-lg font-medium">Choose QR style</h1>
                        <div className="flex flex-1 gap-x-4 flex-wrap">
                          <div
                            className={`h-16 w-16 p-2 border rounded-lg cursor-pointer flex items-center justify-center ${
                              activeStyle === "square"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrStyle("squares");
                              setActiveStyle("square");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/boxes.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 p-2 border rounded-lg cursor-pointer flex items-center justify-center ${
                              activeStyle === "dots"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrStyle("dots");
                              setActiveStyle("dots");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/dots.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 p-2 border rounded-lg cursor-pointer flex items-center justify-center ${
                              activeStyle === "fluid"
                                ? ` border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrStyle("fluid");
                              setActiveStyle("fluid");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/fluid.jpeg")}
                              className="object-cover"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-4 pb-5 space-y-2 ">
                        <h1 className="text-lg font-medium">
                          Choose corner type
                        </h1>
                        <div className="flex flex-1 gap-x-4 flex-wrap gap-y-2 sm:gap-y-0">
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "circle"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [100, 100, 100, 100],
                                inner: [100, 100, 100, 100],
                              });
                              setActiveCorner("circle");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/circle.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "outer-circle"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [100, 100, 100, 100],
                                inner: [0, 0, 0, 0],
                              });
                              setActiveCorner("outer-circle");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/outer-circle.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "inner-circle"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [0, 0, 0, 0],
                                inner: [100, 100, 100, 100],
                              });
                              setActiveCorner("inner-circle");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/inner-circle.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "square"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [0],
                                inner: [0],
                              });
                              setActiveCorner("square");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/sqaure.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "topleft-square"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [0, 100, 100, 100],
                                inner: [0],
                              });
                              setActiveCorner("topleft-square");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/topleft-square.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "topleft-circle"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [0, 100, 100, 100],
                                inner: [100, 100, 100, 100],
                              });
                              setActiveCorner("topleft-circle");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/topleft-circle.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "tleftbright-circle"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [0, 100, 0, 100],
                                inner: [100, 100, 100, 100],
                              });
                              setActiveCorner("tleftbright-circle");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/tleftbright-circle.jpeg")}
                              className="object-cover"
                            />
                          </div>
                          <div
                            className={`h-16 w-16 border rounded-lg flex items-center justify-center cursor-pointer p-4 ${
                              activeCorner === "instagram"
                                ? `border-2 border-[#019ca2]`
                                : ``
                            }`}
                            onClick={() => {
                              setQrCorner({
                                outer: [25, 25, 25, 25],
                                inner: [100, 100, 100, 100],
                              });
                              setActiveCorner("instagram");
                            }}
                          >
                            <img
                              src={require("../../assets/images/qr-codes/instagram.jpeg")}
                              className="object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border dark:border-neutral-700 rounded-xl">
                      <div className="space-y-2">
                        <h1 className="p-4 text-xl font-semibold border-b dark:border-b-neutral-700">
                          Choose your colors
                        </h1>
                        <h2 className="text-sm font-semibold text-gray-500 pt-2 px-4">
                          FOREGROUND COLOR
                        </h2>
                        <div className="flex flex-1 gap-x-4 px-4 flex-wrap gap-y-2 sm:gap-y-0">
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-black ${
                              qrColor == "#000"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#000")}
                          ></div>

                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#4b4b4b] ${
                              qrColor == "#4b4b4b"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#4b4b4b")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#5d4037] ${
                              qrColor == "#5d4037"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#5d4037")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#1b2631] ${
                              qrColor == "#1b2631"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#1b2631")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#0b3d91] ${
                              qrColor == "#0b3d91"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#0b3d91")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#2c3e50] ${
                              qrColor == "#2c3e50"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#2c3e50")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#4a235a] ${
                              qrColor == "#4a235a"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrColor("#4a235a")}
                          ></div>
                        </div>
                      </div>
                      <div className="p-4  space-y-2">
                        <h2 className="text-sm font-semibold text-gray-500 pt-4">
                          BACKGROUND COLOR
                        </h2>
                        <div className="flex flex-1 gap-x-4 flex-wrap gap-y-2 sm:gap-y-0">
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#fff] ${
                              qrBgColor == "#fff"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => {
                              setQrBgColor("#fff");
                            }}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#f0f0f0] ${
                              qrBgColor == "#f0f0f0"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => {
                              setQrBgColor("#f0f0f0");
                            }}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#f5f5f5] ${
                              qrBgColor == "#f5f5f5"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrBgColor("#f5f5f5")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#e8f5e9] ${
                              qrBgColor == "#e8f5e9"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrBgColor("#e8f5e9")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#e3f2fd] ${
                              qrBgColor == "#e3f2fd"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrBgColor("#e3f2fd")}
                          ></div>
                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#fff3e0] ${
                              qrBgColor == "#fff3e0"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrBgColor("#fff3e0")}
                          ></div>

                          <div
                            className={`h-8 w-8 cursor-pointer border rounded-full bg-[#f8e9f4] ${
                              qrBgColor == "#f8e9f4"
                                ? ` border-[3px] ring-2 ring-[#019ca2] border-white`
                                : ``
                            }`}
                            onClick={() => setQrBgColor("#f8e9f4")}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-2 border dark:border-neutral-700 rounded-xl">
                      <h1 className="p-4 text-xl font-semibold border-b dark:border-b-neutral-700">
                        Advanced options
                      </h1>
                      <div className="flex flex-1 gap-x-4 p-4">
                        <div className="">
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                              checked={logoVisible}
                              onClick={() => setLogoVisible((prev) => !prev)}
                              disabled={
                                activeSubscription?.subscription?.name ==
                                "Freemium"
                              }
                            />
                            <div className="absolute md:relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                            <span className="ms-2 text-sm font-medium text-gray-500 dark:text-gray-300">
                              Show/Hide Atz logo{" "}
                              {activeSubscription?.subscription?.name ==
                                "Freemium" && <span>(Upgrade to switch)</span>}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 h-auto max-w-md text-center justify-center border dark:border-neutral-700 rounded-xl sm:mx-4 mx-0">
                  <div className="flex flex-1 flex-col justify-between ">
                    <div className="text-xl py-4 border-b dark:border-b-neutral-700 font-semibold dark:text-neutral-300 ">
                      PREVIEW
                    </div>
                    <div className="flex py-2 justify-center items-center sm:hidden">
                      {loading && (
                        <div>
                          <SmallLoader />
                        </div>
                      )}
                      {!loading && (
                        <QRCode
                          size={200}
                          value={process.env.REACT_APP_ATZ_URL}
                          logoImage={
                            logoVisible
                              ? require("../../assets/favicon.ico")
                              : ""
                          }
                          logoPadding={2}
                          logoWidth={30}
                          logoHeight={30}
                          fgColor={qrColor}
                          bgColor={qrBgColor}
                          qrStyle={qrStyle}
                          eyeRadius={qrCorner}
                          ecLevel="H"
                        />
                      )}
                    </div>
                    <div className="hidden py-2 justify-center items-center sm:flex">
                      {loading && (
                        <div>
                          <SmallLoader />
                        </div>
                      )}
                      {!loading && (
                        <QRCode
                          size={300}
                          value={process.env.REACT_APP_ATZ_URL}
                          logoImage={
                            logoVisible
                              ? require("../../assets/favicon.ico")
                              : ""
                          }
                          logoPadding={2}
                          logoWidth={30}
                          logoHeight={30}
                          fgColor={qrColor}
                          bgColor={qrBgColor}
                          qrStyle={qrStyle}
                          eyeRadius={qrCorner}
                          ecLevel="H"
                        />
                      )}
                    </div>
                    <div className="py-2 hidden" ref={canvasRef}>
                      <QRCode
                        size={300}
                        value={tempUrl}
                        logoImage={
                          logoVisible ? require("../../assets/favicon.ico") : ""
                        }
                        logoPadding={2}
                        logoWidth={30}
                        logoHeight={30}
                        fgColor={qrColor}
                        bgColor={qrBgColor}
                        qrStyle={qrStyle}
                        eyeRadius={qrCorner}
                        ecLevel="H"
                      />
                    </div>
                    <div
                      className="border-t dark:border-t-neutral-700 text-xl py-4 hover:bg-neutral-100 cursor-pointer font-semibold"
                      onClick={handleReset}
                    >
                      RESET
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 items-center justify-center">
                <button
                  className="px-10 border font-semibold rounded-r-full rounded-l-full py-2 border-primary-800 text-primary-800 hover:bg-primary-800 hover:text-white disabled:bg-neutral-300 disabled:cursor-not-allowed disabled:hover:text-inherit"
                  onClick={handleCustomize}
                >
                  Apply changes
                </button>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default CustomizeQr;
