import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "./subcomponents/Loader";
import Error from "./Error";

const Callback = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [code, setCode] = useState(null);
  const navigate = useNavigate();

  // Extract code from URL
  useEffect(() => {
    const codeRegex = /code=([^&]+)/;
    const isMatch = window.location.href.match(codeRegex);
    if (isMatch) {
      setCode(isMatch[1]);
    }
  }, []);

  // Fetch user data with code
  useEffect(() => {
    const getId = async () => {
      const data = {
        data: {
          code,
          clientName: "atz",
          redirectUri: process.env.REACT_APP_ATZ_CALLBACK_URL,
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_GATEWAY_URL}/core/get-token`,
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 200) {
          const { data: responseData } = response.data;
          const { user, token } = responseData;

          // Store in localStorage
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("contentLoad", "true");

          // Initialize any required UI components
          window.HSStaticMethods?.autoInit();
        } else {
          setError(true);
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (code) {
      getId();
    }
  }, [code]);

  // Navigate after successful data fetch
  useEffect(() => {
    if (!loading && !error) {
      navigate("/dashboard");
    }
  }, [loading, error, navigate]);

  if (loading) return <Loader />;
  if (error) return <Error />;
  return null;
};

export default Callback;
