import React, { useState, useEffect } from "react";
import axios from "axios";
import AiTabCards from "./AiTabCards";

const AiTab = ({ id, countChild }) => {
  const [notFound, setNotFound] = useState(false);
  const [lastColor, setLastColor] = useState(null);
  const [linkData, setLinkData] = useState([]);
  const [kpiData, setKpiData] = useState({});
  const colors = [
    "#007BFF",
    "#FF5733",
    "#32CD32",
    "#FFD700",
    "#FF00FF",
    "#40E0D0",
    "#FF7F50",
    "#6A0DAD",
    "#FF69B4",
    "#6F00FF",
    "#DC143C",
    "#00BFFF",
  ];

  let prevColor = 0;

  const randomColor = () => {
    let random = Math.floor(Math.random() * 10) % 10;
    if (random !== prevColor) {
      prevColor = random;
      return random;
    } else {
      while (prevColor == random) {
        random = Math.floor(Math.random() * 10) % 10;
      }
      prevColor = random;
      return random;
    }
  };
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [window.location.pathname]);
  useEffect(() => {
    const fetchKpi = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/core/dashboard-kpi`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);
        if (response.status === 200) {
          setKpiData(response.data.data);
        }
      } catch (error) {
        console.log(error);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    fetchKpi();
  }, []);

  useEffect(() => {
    const getAiDetails = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          linkId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/link-contents`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;
        if (response.status === 200) {
          setNotFound(false);
          setLinkData(data);
        }
        console.log(linkData);
      } catch (error) {
        if (error.response.status === 404) {
          setNotFound(true);
        }
      }
    };
    getAiDetails();
  }, [countChild]);

  return (
    <>
      {!notFound && (
        <>
          <div className="flex flex-col">
            <div className="flex -mt-4 justify-between">
              <div className="inline-flex relative bg-white border  rounded-[2rem] p-2 text-neutral-500 dark:bg-neutral-800 border-[#019ca2] items-center gap-x-2 px-4 text-sm">
                <span className="hidden sm:block">Available AI credits :</span>
                <svg height="14" width="14" fill="#019ca2" viewBox="0 0 24 24">
                  <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                </svg>
                {kpiData &&
                  kpiData?.aiCredits?.totalAiCredits -
                    kpiData?.aiCredits?.utilizedAiCredits}
              </div>
              <button
                className="btn dark:bg-neutral-800"
                onClick={() => (window.location.href = `/gen-ai?id=${id}`)}
              >
                <svg
                  height="18"
                  width="18"
                  fill="#FFFFFF"
                  viewBox="0 0 24 24"
                  data-name="Layer 1"
                  id="Layer_1"
                  className="sparkle"
                >
                  <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                </svg>

                <span className="text">Generate with AI</span>
              </button>
            </div>

            {linkData &&
              linkData.map((linkData, index) => (
                <AiTabCards
                  key={linkData.id}
                  linkData={linkData}
                  color={prevColor == null ? colors[0] : colors[randomColor()]}
                />
              ))}
          </div>
        </>
      )}
      {notFound && (
        <>
          <div className="  flex flex-1 flex-col justify-center items-center text-center">
            <div className="pb-4 ">
              <span className="bg-clip-text gap-x-1 inline-flex items-center bg-gradient-to-l from-purple-400 to-[#019ca2] text-transparent text-7xl font-bold">
                <svg
                  className="flex-shrink-0"
                  height="36px"
                  width="36px"
                  version="1.1"
                  id="_x32_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                  fill="#019ca2"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path
                        className="st0"
                        d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                      ></path>{" "}
                      <path
                        className="st0"
                        d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                      ></path>{" "}
                      <path
                        className="st0"
                        d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                      ></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
                Atz Ai
                {/* <!-- 202 --> */}
              </span>
            </div>
            <div className="max-w-sm mx-auto flex flex-col justify-center items-center">
              <p className="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                Enhance Your Marketing with Atz AI
              </p>
              <p className="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                Transform Your Marketing Strategy with Tailored SMS and Email
                Content from Atz AI
              </p>
              <div className="pt-5">
                <button
                  className="btn"
                  onClick={() => (window.location.href = `/gen-ai?id=${id}`)}
                >
                  <svg
                    height="18"
                    width="18"
                    fill="#FFFFFF"
                    viewBox="0 0 24 24"
                    data-name="Layer 1"
                    id="Layer_1"
                    className="sparkle"
                  >
                    <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                  </svg>

                  <span className="text">Generate with AI</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AiTab;
