import React from "react";

const AiSmsCard = ({ data, i }) => {
  const { content: body } = data;

  return (
    <div className="relative flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
      <div className="flex justify-between items-center border-b rounded-t-xl py-3 px-4 md:px-5 dark:border-neutral-700">
        <h3 className="inline-flex items-center gap-x-2 text-lg font-semibold text-gray-800 dark:text-white uppercase">
          <div className="flex border border-gray-300 text-gray-500 dark:text-neutral-300 dark:border-neutral-600 size-7 rounded-lg items-center justify-center">
            <svg
              className="shrink-0 size-4"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M2 8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M2 16.5H8"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M2 12.5H5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>

          <div>{data.header}</div>
        </h3>
      </div>
      <div className="p-4 md:p-5">
        <p className="text-gray-500 dark:text-neutral-400">{body}</p>
      </div>
      <div className="absolute top-0 right-0 transform translate-x-1 -translate-y-1/2 bg-[#019ca2] text-white text-xs font-bold py-1 px-2 rounded-full">
        {i}
      </div>
    </div>
  );
};

export default AiSmsCard;
