import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SmallLoader from "../../subcomponents/SmallLoader";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Failure = () => {
  const [loadingPay, setLoadingPay] = useState(true);
  const [invalidLink, setInvalidLink] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    const checkPayment = async () => {
      const tokenData = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }
      const requestData = {
        data: {
          token: token,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/cancel-for-ai-credits`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        const response = await axios(config);
        console.log(response.status);
        // console.log("passedData : ", selectedPlan.amount);
        if (response.status === 200) {
          logEvent(analytics, `CTA_AI_CREDIT_MAKE_PAYMENT_FAILED`);
          setInvalidLink(false);
          setLoadingPay(false);
          document.getElementById("failureButton").click();
        }
      } catch (err) {
        console.log(err.response);
        if (err.response.status === 404) {
          setInvalidLink(true);
          logEvent(analytics, `CTA_AI_CREDIT_MAKE_PAYMENT_CANCELLED`);
          document.getElementById("failureButton").click();
        }
        setLoadingPay(false);
      }
    };
    checkPayment();
  }, []);

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);

  return (
    <div>
      <div
        id="hs-scale-animation-modal1"
        className="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="hs-scale-animation-modal1-label"
      >
        <div className="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div className="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                {!invalidLink && "Payment Cancelled"}
                {invalidLink && "Invalid Link"}
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                onClick={() => (window.location.href = "/billing/ai-credits")}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              className="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fillRule="evenodd"
                        fill="#ff0000"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">
                  {!invalidLink && "Payment Transaction Cancelled"}
                  {invalidLink &&
                    "You might have visited this link by mistake."}
                </div>
                <div className="text-xs flex text-center px-5">
                  {!invalidLink &&
                    "You have cancelled the payment for this package. In order to make payment again go to"}
                  {invalidLink &&
                    "Kindly head back to the Ai-credits page by clicking the following link below -"}
                </div>
                <a
                  href="/billing/ai-credits"
                  className="text-primary-700 text-sm"
                >
                  'atz.nidavellirs.net/billing/ai-credits'
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingPay && (
        <div className="flex flex-1 min-h-screen justify-center items-center">
          <SmallLoader />
        </div>
      )}
    </div>
  );
};

export default Failure;
