import React, { useState, useEffect } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import axios from "axios";
import SubscriptionCard from "./SubscriptionCard";
import Loader from "../subcomponents/Loader";
import { useLocation } from "react-router-dom";
import SmallLoader from "../subcomponents/SmallLoader";
import DashboardFooter from "../Dashboard/DashboardFooter";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const Subscriptions = () => {
  const [planData, setPlanData] = useState([]);
  const [activePlan, setActivePlan] = useState({});
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(null);

  const location = useLocation();
  useEffect(() => {
    const planDetails = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/active-subscriptions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: {},
      };
      try {
        const response = await axios(config);
        console.log(response.data);

        if (response.status === 200) {
          setPlan(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    planDetails();
  }, []);
  useEffect(() => {
    const fetchPlans = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/subscription-packages`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: {},
      };
      try {
        setLoading(true);
        const response = await axios(config);
        console.log(response.data.data);

        setPlanData(response.data.data.subscriptions);
        setActivePlan(response.data.data.activeSubscription);
        if (response.status === 200) {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };
    fetchPlans();
  }, []);

  const handleCancel = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/cancel-subscription`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: {},
    };
    try {
      logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL`);
      setLoading(true);
      const response = await axios(config);
      console.log(response.data.data);

      // setPlanData(response.data.data.subscriptions);
      // setActivePlan(response.data.data.activeSubscription);
      if (response.status === 200) {
        logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_SUCCESS`);
        setLoading(false);
        document.getElementById("failureButton").click();
      }
    } catch (e) {
      logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_FAILED`);
      setLoading(false);
      console.log(e);
    }
  };
  const handleRenew = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-request`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          packageId: plan?.subscriptionId,
        },
      },
    };
    try {
      logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_RENEW`);
      setLoading(true);
      const response = await axios(config);
      console.log(response.data.data);

      // setPlanData(response.data.data.subscriptions);
      // setActivePlan(response.data.data.activeSubscription);
      if (response.status === 200) {
        logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_RENEW_SUCCESS`);
        window.location.href = response.data.data;
      }
    } catch (e) {
      logEvent(analytics, `CTA_SUBSCRIPTION_CANCEL_RENEW_FAILED`);
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, loadingPayment, plan]);
  return (
    <>
      {loadingPayment && <Loader />}
      {!loadingPayment && (
        <>
          <>
            <div
              id="hs-scale-animation-modal1"
              className="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
              role="dialog"
              tabIndex="-1"
              aria-labelledby="hs-scale-animation-modal1-label"
            >
              <div className="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                  {/* <!-- Header --> */}
                  <div className="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
                    <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                      Subscription cancelled successfully
                    </h3>

                    {/* <!-- End Header --> */}
                    <button
                      type="button"
                      className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                      aria-label="Close"
                      onClick={() => window.location.reload()}
                      // data-hs-overlay="#hs-scale-animation-modal1"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </button>
                  </div>
                  <div
                    className="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
                  >
                    <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                      <div>
                        <svg
                          fill="#16A34A"
                          className="size-10"
                          viewBox="0 0 24 24"
                          id="d9090658-f907-4d85-8bc1-743b70378e93"
                          data-name="Livello 1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <title>prime</title>
                            <path
                              id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
                              data-name="done circle"
                              d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="text-sm font-semibold -mb-1">
                        We have successfully processed your subscription
                        cancellation.
                      </div>
                      <div className="text-xs flex  text-center px-6 mt-3 ">
                        <div className="space-y-1">
                          <div className="underline font-bold ">
                            What to expect ?
                          </div>
                          <div>
                            <ul className="text-start">
                              <li>
                                <strong>Access</strong>: You will retain access
                                to your subscription benefits till the end of
                                this month.
                              </li>

                              <li>
                                <strong>Billing</strong>: No further charges
                                will be applied. Any payments already made are
                                non-refundable.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          <>
            <div
              id={`hs-vertically-centered-modal`}
              className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
              role="dialog"
              tabIndex="-1"
              aria-labelledby={`hs-vertically-centered-modal-label`}
            >
              <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                  <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3
                      id="hs-vertically-centered-modal-label"
                      className="font-bold text-gray-800 dark:text-white text-base"
                    >
                      Subscription Cancellation Confirmation
                    </h3>
                    <button
                      type="button"
                      className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                      aria-label="Close"
                      data-hs-overlay={`#hs-vertically-centered-modal`}
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-4 overflow-y-auto">
                    <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                      <div>
                        <svg
                          viewBox="0 0 16 16"
                          fill="none"
                          className="size-10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                              stroke="none"
                              fillRule="evenodd"
                              fill="#ff0000"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="text-sm font-semibold -mb-1">
                        Are you sure you want to cancel your subscription?
                      </div>
                      <div className="text-xs flex  text-center px-5">
                        <span>
                          You can reactivate your subscription at any time.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
                    <button
                      type="button"
                      className="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-red-400 hover:bg-red-600 text-red-600 hover:text-white dark:hover:bg-red-700 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                      data-hs-overlay={`#hs-vertically-centered-modal`}
                      onClick={handleCancel}
                    >
                      Yes! Cancel subscription
                    </button>
                    <button
                      type="button"
                      className="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                      data-hs-overlay={`#hs-vertically-centered-modal`}
                    >
                      No, keep subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          <>
            <div
              id={`hs-vertically-centered-modal-1`}
              className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
              role="dialog"
              tabIndex="-1"
              aria-labelledby={`hs-vertically-centered-modal-1-label`}
            >
              <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                  <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3
                      id="hs-vertically-centered-modal-1-label"
                      className="font-bold text-gray-800 dark:text-white text-base"
                    >
                      Renew Your Subscription
                    </h3>
                    <button
                      type="button"
                      className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                      aria-label="Close"
                      data-hs-overlay={`#hs-vertically-centered-modal-1`}
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-4 overflow-y-auto">
                    <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                      <div>
                        <svg
                          viewBox="0 0 16 16"
                          fill="none"
                          className="size-10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                              stroke="none"
                              fillRule="evenodd"
                              fill="#019ca2"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="text-sm font-semibold -mb-1 text-center">
                        Renew now to continue enjoying uninterrupted access to
                        all our features and services.
                      </div>
                      <div className="text-xs flex  text-center px-5">
                        <span>
                          By renewing, you'll ensure that your benefits remain
                          active without any service disruptions.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
                    <button
                      type="button"
                      className="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-primary-400 hover:bg-primary-600 text-primary-600 hover:text-white dark:hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                      data-hs-overlay={`#hs-vertically-centered-modal-1`}
                      onClick={handleRenew}
                    >
                      Renew Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          <DashboardHeader />
          <DashboardSidebar />
          <DashboardFooter />
          <main
            id="content"
            className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
          >
            <div className="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
              <div className="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
                <div>
                  {/* <!-- Title --> */}
                  <div className="flex flex-col flex-1 mb-12 gap-y-2">
                    {plan && plan?.subscriptionStatus === "activated" && (
                      <div className="flex justify-end">
                        <button
                          className="py-1.5 px-4 inline-flex items-center gap-x-4  text-xs font-medium rounded-full  dark:text-white text-red-500  border border-red-500 dark:border-neutral-600 mr-3 hover:bg-red-600 dark:hover:bg-red-500 hover:text-white dark:hover:text-white"
                          aria-haspopup="dialog"
                          aria-expanded="false"
                          aria-controls={`hs-vertically-centered-modal`}
                          data-hs-overlay={`#hs-vertically-centered-modal`}
                        >
                          <span className="text-xs relative -ms-1 space-x-1">
                            <span className="ps-2.5 ">Cancel Subscription</span>
                          </span>
                        </button>
                      </div>
                    )}

                    {plan &&
                      (plan?.subscriptionStatus === "cancelled" ||
                        plan?.subscriptionStatus === "expired") && (
                        <div className="flex justify-end">
                          <button
                            className="py-1.5 px-4 inline-flex items-center gap-x-4  text-xs font-medium rounded-full  dark:text-white text-primary-500  border border-primary-500 dark:border-neutral-600 mr-3 hover:bg-primary-600 dark:hover:bg-primary-500 hover:text-white dark:hover:text-white"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls={`hs-vertically-centered-modal-1`}
                            data-hs-overlay={`#hs-vertically-centered-modal-1`}
                          >
                            <span className="text-xs relative -ms-1 space-x-1">
                              <span className="ps-2.5 ">
                                Renew Subscription
                              </span>
                            </span>
                          </button>
                        </div>
                      )}
                    <div className="text-center">
                      <h1 className="text-3xl font-bold text-gray-800 dark:text-neutral-200">
                        Go Beyond{" "}
                        <span className="text-[#019ca2]">Shortening</span> –
                        Experience{" "}
                        <span className="text-[#019ca2]">Intelligent</span>{" "}
                        Linking
                      </h1>
                      <h2 className="text-base italic text-gray-800 dark:text-neutral-300">
                        Upgrade for AI-enhanced features that drive engagement
                        and track performance.
                      </h2>
                    </div>
                  </div>
                  {/* <!-- End Title --> */}

                  {/* <!-- Toggle --> */}
                  <div className="flex justify-center mb-6 hidden">
                    <div
                      id="hs-pro-toggle-count"
                      className="p-0.5 inline-block bg-gray-100 rounded-full dark:bg-neutral-900"
                    >
                      <label
                        for="toggle-count-by-card"
                        className="relative inline-block py-1.5 px-3.5"
                      >
                        <input
                          id="toggle-count-by-card"
                          name="hs-pro-toggle-count"
                          type="radio"
                          className="peer absolute top-0 end-0 size-full border-transparent bg-transparent bg-none text-transparent rounded-full cursor-pointer disabled:opacity-50 disabled:pointer-events-none before:absolute before:inset-0 before:size-full before:rounded-full focus:ring-offset-0 checked:before:bg-white checked:before:shadow-sm checked:bg-none focus:ring-transparent dark:checked:before:bg-neutral-800 dark:focus:ring-offset-transparent"
                          checked
                        />
                        <span className="relative z-10 inline-flex justify-center items-center gap-x-2 text-sm font-medium text-gray-800 cursor-pointer peer-disabled:cursor-default dark:text-neutral-200">
                          Monthly
                        </span>
                      </label>
                      <label
                        for="toggle-count-with-paypal"
                        className="relative inline-block py-1.5 px-3.5"
                      >
                        <input
                          id="toggle-count-with-paypal"
                          name="hs-pro-toggle-count"
                          type="radio"
                          className="peer absolute top-0 end-0 size-full border-transparent bg-transparent bg-none text-transparent rounded-full cursor-pointer disabled:opacity-50 disabled:pointer-events-none before:absolute before:inset-0 before:size-full before:rounded-full focus:ring-offset-0 checked:before:bg-white checked:before:shadow-sm checked:bg-none focus:ring-transparent dark:checked:before:bg-neutral-800 dark:focus:ring-offset-transparent"
                        />
                        <span className="relative z-10 inline-flex justify-center items-center gap-x-2 text-sm font-medium text-gray-800 cursor-pointer peer-disabled:cursor-default dark:text-neutral-200">
                          Annual
                        </span>
                        <span className="absolute -top-6 start-10">
                          <span className="flex items-center -mt-5">
                            <svg
                              className="flex-shrink-0 -mr-6 text-gray-400 dark:text-neutral-600"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 99.3 57"
                              width="48"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="4"
                                strokeLinecap="round"
                                stroke-miterlimit="10"
                                d="M2,39.5l7.7,14.8c0.4,0.7,1.3,0.9,2,0.4L27.9,42"
                              ></path>
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="4"
                                strokeLinecap="round"
                                stroke-miterlimit="10"
                                d="M11,54.3c0,0,10.3-65.2,86.3-50"
                              ></path>
                            </svg>
                            <span className="block mt-3">
                              <span className="inline-flex items-center gap-1.5 py-1 px-2 whitespace-nowrap text-xs font-medium bg-blue-600 text-white rounded-full dark:bg-blue-500">
                                Save up to 10%
                              </span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  {/* <!-- End Toggle --> */}

                  {/* <!-- Pricing Cards Grid --> */}
                  {loading && (
                    <div className="flex flex-1 justify-center items-center min-h-96">
                      {" "}
                      <SmallLoader />
                    </div>
                  )}
                  {!loading && (
                    <div className="grid sm:grid-cols-2  2xl:grid-cols-4 2xl:gap-6 gap-4">
                      {/* <!-- Card --> */}
                      {planData &&
                        activePlan &&
                        planData?.map((data) => (
                          <SubscriptionCard
                            plan={planData}
                            planData={data}
                            activePlan={activePlan}
                            setLoadingPayment={setLoadingPayment}
                          />
                        ))}

                      {/* <!-- End Card --> */}
                    </div>
                  )}
                  {/* <!-- End Pricing Cards Grid --> */}

                  <div className="mt-6 flex justify-center items-center gap-x-3 hidden">
                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                      Need a custom plan?
                    </p>
                    <a
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                      href="#"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
                {/* <!-- Comparison Table --> */}
                <div className="relative hidden">
                  <div className="py-8 text-center">
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Compare plans
                    </h2>
                  </div>

                  {/* <!-- Header --> */}
                  <div className="hidden lg:block sticky top-14 start-0 py-2 bg-white dark:bg-neutral-800">
                    {/* <!-- Grid --> */}
                    <div className="grid lg:grid-cols-6 lg:gap-x-3 lg:gap-x-6">
                      <div className="col-span-2">
                        {/* <!-- Header --> */}
                        <div className="h-full"></div>
                        {/* <!-- End Header --> */}
                      </div>
                      {/* <!-- End Col --> */}

                      <div className="col-span-1">
                        {/* <!-- Header --> */}
                        <div className="p-4 h-full flex flex-col justify-between text-center">
                          <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                              Free
                            </h4>
                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                              Free forever
                            </p>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              disabled
                            >
                              Free trial
                            </button>
                          </div>
                        </div>
                        {/* <!-- End Header --> */}
                      </div>

                      <div className="col-span-1">
                        {/* <!-- Header --> */}
                        <div className="p-4 h-full flex flex-col justify-between text-center">
                          <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                              Startup
                            </h4>
                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                              $29 per month billed annually
                            </p>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              disabled
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                        {/* <!-- End Header --> */}
                      </div>

                      <div className="col-span-1">
                        {/* <!-- Header --> */}
                        <div className="p-4 h-full flex flex-col justify-between text-center">
                          <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                              Team
                            </h4>
                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                              $59 per month billed annually
                            </p>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              Upgrade
                            </button>
                          </div>
                        </div>
                        {/* <!-- End Header --> */}
                      </div>

                      <div className="col-span-1">
                        {/* <!-- Header --> */}
                        <div className="p-4 h-full flex flex-col justify-between text-center">
                          <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                              Enterprise
                            </h4>
                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                              $119 per month billed annually
                            </p>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              disabled
                            >
                              Upgrade
                            </button>
                          </div>
                        </div>
                        {/* <!-- End Header --> */}
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                  </div>
                  {/* <!-- End Header --> */}

                  {/* <!-- Section --> */}
                  <div className="space-y-4 lg:space-y-0">
                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 lg:py-3">
                        <span className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                          General
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}
                      {/* 
              <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Number of seats
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            1
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            Up to 3
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            Up to 10
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            Unlimited
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Storage
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            15 GB
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            1 TB
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            15 TB
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                            Unlimited
                          </span>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Email sharing
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Any time, anywhere access
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}
                  </div>
                  {/* <!-- End Section --> */}

                  {/* <!-- Section --> */}
                  <div className="mt-6 space-y-4 lg:space-y-0">
                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 lg:py-3">
                        <span className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                          Financial data
                        </span>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Open/High/Low/Close
                        </span>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}
                    {/*  */}
                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Price-volume difference indicator
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}
                  </div>
                  {/* <!-- End Section --> */}

                  {/* <!-- Section --> */}
                  <div className="mt-6 space-y-4 lg:space-y-0">
                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 lg:py-3">
                        <span className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                          On-chain data
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Network growth
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Average token age consumed
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Exchange flow
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Total ERC20 exchange funds flow
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Transaction volume
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Total circulation (beta)
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Velocity of tokens (beta)
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}
                    {/*  */}
                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          ETH gas used
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}
                  </div>
                  {/* <!-- End Section --> */}

                  {/* <!-- Section --> */}
                  <div className="mt-6 space-y-4 lg:space-y-0">
                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 lg:py-3">
                        <span className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                          Social data
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="hidden lg:block lg:col-span-1 py-0.5 lg:py-3 lg:text-center"></li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Dev activity
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                      {/*  */}
                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Topic search
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}

                    {/* <!-- List --> */}
                    <ul className="grid lg:grid-cols-6 lg:gap-x-6">
                      {/* <!-- Item --> */}
                      <li className="lg:col-span-2 pb-1.5 lg:py-2">
                        <span className="text-sm font-medium lg:font-normal text-gray-800 dark:text-neutral-200">
                          Relative social dominance
                        </span>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Free
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Startup
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Team
                          </span>
                          <svg
                            className="size-4 lg:mx-auto text-gray-500 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}

                      {/* <!-- Item --> */}
                      <li className="col-span-1 py-0.5 lg:py-2 lg:text-center">
                        <div className="grid grid-cols-2 lg:block">
                          <span className="lg:hidden text-sm text-gray-500 dark:text-neutral-200">
                            Enterprise
                          </span>
                          <svg
                            className="size-5 lg:mx-auto text-blue-600 dark:text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </li>
                      {/* <!-- End Item --> */}
                    </ul>
                    {/* <!-- End List --> */}
                  </div>
                  {/* <!-- End Section --> */}
                </div>
                {/* <!-- End Comparison Table --> */}
              </div>
            </div>
          </main>
        </>
      )}
      <div
        id="hs-scale-animation-modal2"
        className="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="hs-scale-animation-modal2-label"
      >
        <div className="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div className="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                Payment Success
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal2"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              className="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    fill="#16A34A"
                    className="size-10"
                    viewBox="0 0 24 24"
                    id="d9090658-f907-4d85-8bc1-743b70378e93"
                    data-name="Livello 1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>prime</title>
                      <path
                        id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
                        data-name="done circle"
                        d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">
                  Payment Success
                </div>
                <div className="text-xs flex text-center px-5">
                  Congratulations, your payment is successfull and respective
                  benefits have been added to your account.
                </div>
                <div className="text-xs flex text-center px-5">
                  This window will automatically close and your page will be
                  reloaded.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-scale-animation-modal1"
        className="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="hs-scale-animation-modal1-label"
      >
        <div className="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div className="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                Payment Failed
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal1"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              className="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fillRule="evenodd"
                        fill="#ff0000"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">
                  Payment Transaction Failed
                </div>
                <div className="text-xs flex text-center px-5">
                  Unfortunately, we encountered an issue processing your
                  payment. Please review your payment method or contact support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
